import { getApiBase } from "_helpers";
import { cookieService } from "_services";

const getAll = () => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `?jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/admin/user${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching Users");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getAllPRGUsers = () => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `?jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/admin/user/general/users${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching Users");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};


const getRoles = () => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `?jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/admin/user/roles${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching roles");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const createUser = (payload) => {
  let body = { ...payload };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();

  return fetch(`${apiBase}/admin/user`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("Error adding User account");
      }

      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getUserGeneral = (userId) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/user/general?userid=${userId}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("An error occured getting User General");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const updateUserGeneral = (payload, userId) => {
  let body = {
    ...payload,
  };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();

  return fetch(`${apiBase}/admin/user/general?userid=${userId}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("Error updating user account");
      }

      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getDbAccess = (userId) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/user/dbaccess?userid=${userId}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("An error occured getting User db access");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const updateDbAccess = (payload) => {
  let body = {
    ...payload,
  };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();

  return fetch(`${apiBase}/admin/user/dbaccess`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("Error updating db access");
      }

      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getEntitiesAccess = (userId) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing
  let jwtCookie = "";

  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/user/entityaccess?userid=${userId}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching User Entity Access");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const updateEntitiesAccess = (payload, userid) => {
  let body = {
    entities: Object.values(payload),
  };
  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing
  return fetch(
    `${apiBase}/admin/user/entityaccess?userid=${userid}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      return { status: `Updated User Entity Permissions for User ${userid}` };
    })
    .catch((err) => {
      throw err;
    });
};

const toggleEntityAccess = (userId, checked) => {
  let body = {};
  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing
  return fetch(
    `${apiBase}/admin/user/entityaccess/toggle?userid=${userId}&active=${checked}`,
    parameters
  )
    .then((response) => {
      if (response.status !== 200) {
        throw new Error("Error updating db access");
      }

      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getUserTypes = () => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `?jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/admin/user/usertype${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching User Types");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

// Action Access
// =================

const getUserActionAccess = (userId) => {

  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";

  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/user/actionaccess?userId=${userId}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching user action access");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const updateUserActionAccess = (payload, userId, accessTypeLOB) => {

  let body = {
    ...payload,
    userId: Number(userId),
    accessTypeLOB: Number(accessTypeLOB)
  };

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body)
  };

  let apiBase = getApiBase();
  let jwtCookie = "";

  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/user/actionaccess?${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching user action access");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const deleteUserActionAccess = (userId) => {

  const parameters = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json"
    },
    credentials: "include"
  };

  let apiBase = getApiBase();
  let jwtCookie = "";

  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/user/actionaccess?userId=${userId}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching user action access");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

// =================


// Status Access
// =================

const getUserStatusAccess = (userId) => {

  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";

  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/user/statusaccess?userId=${userId}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching user status access");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const updateUserStatusAccess = (userId, statusId, LOBID, statusType, statusAccessType) => {

  let body = {
    userId,
    statusId,
    LOBID,
    statusType,
    statusAccessType
  };

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body)
  };

  let apiBase = getApiBase();
  let jwtCookie = "";

  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/user/statusaccess?${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error updating user status access");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const deleteUserStatusAccess = (statusId) => {

  const parameters = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include"
  };

  let apiBase = getApiBase();
  let jwtCookie = "";

  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/user/statusaccess?userStatusAccessId=${statusId}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error updating user status access");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const deleteAllUserStatusAccess = (userId) => {

  const parameters = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include"
  };

  let apiBase = getApiBase();
  let jwtCookie = "";

  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/user/statusaccess/deleteall?userId=${userId}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error updating user status access");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

/* =================
DAMAGE INVESTIGATOR
*/
const getUserDamageInvestigator = async (userId) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";

  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  try {
    const response = await fetch(
      `${apiBase}/admin/user/damageinvestigator?userId=${userId}${jwtCookie}`,
      parameters
    );
    if (response.status === 403) {
      throw new Error("Invalid Auth Token");
    }
    if (response.status !== 200) {
      throw new Error("error fetching user damage investigator");
    }
    return await response.json();
  } catch (err) {
    throw err;
  }
};

const updateUserDamageInvestigator = async (userId, isDamageInvestigator, damageInvestigatorStates, damageInvestigatorLOB, superRegion, MidManager, TeamLead, MidManagerID, TeamLeadID, SecondTeamLeadID, MidBoss, bossID) => {
  const body = {
    isDamageInvestigator,
    damageInvestigatorStates,
    damageInvestigatorLOB,
    superRegion,
    MidManager, 
    TeamLead, 
    MidManagerID, 
    TeamLeadID, 
    SecondTeamLeadID,
    MidBoss,
    bossID
  }
  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body)
  };

  let apiBase = getApiBase();
  let jwtCookie = "";

  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  try {
    const response = await fetch(
      `${apiBase}/admin/user/damageinvestigator?userId=${userId}${jwtCookie}`,
      parameters
    );
    if (response.status === 403) {
      throw new Error("Invalid Auth Token");
    }
    if (response.status !== 200) {
      throw new Error("error updating user damage investigator");
    }
    return await response.json();
  } catch (err) {
    throw err;
  }
};

const deleteUserDamageInvestigator = async (userId) => {
  const parameters = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";

  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  try {
    const response = await fetch(
      `${apiBase}/admin/user/damageinvestigator?userId=${userId}${jwtCookie}`,
      parameters
    );
    if (response.status === 403) {
      throw new Error("Invalid Auth Token");
    }
    if (response.status !== 200) {
      throw new Error("error deleting user damage investigator");
    }
    return await response.json();
  } catch (err) {
    throw err;
  }
};

/* =================
NOTIFICATIONS
*/
const getUserNotifications = async (userId) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";

  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  try {
    const response = await fetch(
      `${apiBase}/admin/user/notifications?userId=${userId}${jwtCookie}`,
      parameters
    );
    if (response.status === 403) {
      throw new Error("Invalid Auth Token");
    }
    if (response.status !== 200) {
      throw new Error("error fetching user notificaitons");
    }
    return await response.json();
  } catch (err) {
    throw err;
  }
};

const updateUserNotifications = async (userId, notificationIds) => {
  const body = {
    notificationIds
  }
  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body)
  };

  let apiBase = getApiBase();
  let jwtCookie = "";

  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  try {
    const response = await fetch(
      `${apiBase}/admin/user/notifications?userId=${userId}${jwtCookie}`,
      parameters
    );
    if (response.status === 403) {
      throw new Error("Invalid Auth Token");
    }
    if (response.status !== 200) {
      throw new Error("error updating user notifications");
    }
    return await response.json();
  } catch (err) {
    throw err;
  }
};

const deleteUserNotifications = async (userId) => {
  const parameters = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";

  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  try {
    const response = await fetch(
      `${apiBase}/admin/user/notifications?userId=${userId}${jwtCookie}`,
      parameters
    );
    if (response.status === 403) {
      throw new Error("Invalid Auth Token");
    }
    if (response.status !== 200) {
      throw new Error("error deleting user notifications");
    }
    return await response.json();
  } catch (err) {
    throw err;
  }
};

const toggleClaimAssistant = async (isCA, userId) => {
  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";

  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  try {
    const response = await fetch(
      `${apiBase}/admin/user/claimassistant?userId=${userId}&isCA=${isCA}${jwtCookie}`,
      parameters
    );
    if (response.status === 403) {
      throw new Error("Invalid Auth Token");
    }
    if (response.status !== 200) {
      throw new Error("error toggling claim assistant status");
    }
    return await response.json();
  } catch (err) {
    throw err;
  }
};

export const adminUsersService = {
  getAll,
  getRoles,
  getAllPRGUsers,
  createUser,
  getUserGeneral,
  updateUserGeneral,
  getDbAccess,
  updateDbAccess,
  getEntitiesAccess,
  updateEntitiesAccess,
  toggleEntityAccess,
  getUserTypes,
  getUserActionAccess,
  updateUserActionAccess,
  deleteUserActionAccess,
  getUserStatusAccess,
  updateUserStatusAccess,
  deleteUserStatusAccess,
  deleteAllUserStatusAccess,
  getUserDamageInvestigator,
  updateUserDamageInvestigator,
  deleteUserDamageInvestigator,
  getUserNotifications,
  updateUserNotifications,
  deleteUserNotifications,
  toggleClaimAssistant
};
