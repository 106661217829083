export const AdminLinks = [
  {
    parentId: "admin",
    id: "admin",
    name: "Admin",
    root: true,
    icon: "security",
    access: true,
  },
  {
    parentId: "admin",
    id: "EntityManagement",
    name: "Entity Management",
    url: "/dashboard/admin/entity",
    admin: true,
    access: false,
  },
  {
    parentId: "admin",
    id: "UserManagement",
    name: "User Management",
    url: "/dashboard/admin/users",
    admin: true,
    access: false,
  },
  {
    parentId: "admin",
    id: "RoleManagement",
    name: "Role Management",
    url: "/dashboard/admin/roles",
    admin: true,
    access: false,
  },
  {
    parentId: "admin",
    id: "PowerBiRoleManagement",
    name: "Power BI Role Management",
    url: "/dashboard/admin/powerBiRoles",
    admin: true,
    access: false,
  },
  {
    parentId: "admin",
    id: "Notifications",
    name: "Notifications",
    url: "/dashboard/admin/notifications",
    admin: true,
    access: false,
  },
  {
    parentId: "admin",
    id: "TeamManagement",
    name: "Recovery Agent Management",
    url: "/dashboard/admin/team-management",
    admin: true,
    access: false,
  },
  // {
  //   parentId: "admin",
  //   id: "GoalManagement",
  //   name: "Goal Management",
  //   url: "/dashboard/admin/goals",
  //   admin: true,
  //   access: false,
  // },
  // {
  //   parentId: "admin",
  //   id: "RoundRobin",
  //   name: "Round Robin",
  //   url: "/dashboard/admin/round-robin",
  //   admin: true,
  //   access: false,
  // },
  {
    parentId: "admin",
    id: "KnowledgeBaseManagement",
    name: "KnowledgeBase",
    url: "/dashboard/admin/knowledge-base",
    admin: true,
    access: false,
  },
  {
    parentId: "admin",
    id: "ClaimStatus",
    name: "Claim Status",
    url: "/dashboard/admin/claim-status",
    admin: true,
    access: false,
  },
  {
    parentId: "admin",
    id: "SmartSheet",
    name: "SmartSheet",
    url: "/dashboard/admin/smartsheet",
    admin: true,
    access: false,
  },
  {
    parentId: "admin",
    id: "LedgerDeletion",
    name: "Ledger Deletion",
    url: "/dashboard/admin/ledgers",
    admin: true,
    access: false,
  },
  {
    parentId: "admin",
    id: "StatusNoteDeletion",
    name: "Status Note Deletion",
    url: "/dashboard/admin/status-notes",
    admin: true,
    access: false,
  },
  {
    parentId: "admin",
    id: "ActionNoteDeletion",
    name: "Action Note Deletion",
    url: "/dashboard/admin/action-notes",
    admin: true,
    access: false,
  },
  {
    parentId: "admin",
    id: "FormControl",
    name: "Form Control",
    url: "/dashboard/admin/form-control",
    admin: true,
    access: false,
  },
  {
    parentId: "admin",
    id: "JobCosting",
    name: "Job Costing",
    url: "/dashboard/admin/job-costing",
    admin: true,
    access: true,
  },
  {
    parentId: "admin",
    id: "ReceiptMaker",
    name: "Receipt Maker",
    url: "/dashboard/admin/receipt-maker",
    admin: true,
    access: true,
  },
  {
    parentId: "admin",
    id: "Remittance",
    name: "Remittance",
    url: "/dashboard/admin/remittance",
    admin: true,
    access: false
  },
];
