import { put, call, all } from "redux-saga/effects";
import { alertConstants, authConstants, adminUserConstants } from "_constants";
import { adminUsersService } from "_services";
import { isEmpty } from "ramda";
import { buildAlertMessage } from "_utility";

export function* getAllUsersAdminSaga() {
  try {
    const response = yield call(adminUsersService.getAll);

    yield put({
      type: adminUserConstants.ADMIN_USERS_GET_ALL_SUCCESS,
      payload: [...response],
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getPRGUsersAdminSaga() {
  try {
    const response = yield call(adminUsersService.getAllPRGUsers);

    yield put({
      type: adminUserConstants.ADMIN_PRG_USERS_GET_SUCCESS,
      payload: [...response],
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getRolesSchemaUserAdminSaga() {
  try {
    const response = yield call(adminUsersService.getRoles);
    yield put({
      type: adminUserConstants.ADMIN_USERS_GET_ROLES_SUCCESS,
      payload: [...response],
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* createUserAdminSaga({ payload }) {
  try {
    yield call(adminUsersService.createUser, payload);

    const response = yield call(adminUsersService.getAll);

    yield put({
      type: adminUserConstants.ADMIN_USERS_GET_ALL_SUCCESS,
      payload: [...response],
    });
    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "User Created" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getGeneralUserAdminSaga({ userId }) {
  try {
    const response = yield call(adminUsersService.getUserGeneral, userId);

    yield put({
      type: adminUserConstants.ADMIN_USERS_GET_GENERAL_SUCCESS,
      payload: { data: { ...response }, userId },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateGeneralUserAdminSaga({ payload, userId }) {
  try {
    const responsePost = yield call(adminUsersService.updateUserGeneral, payload, userId);

    if (isEmpty(responsePost)) {
      return;
    }
    const response = yield call(adminUsersService.getUserGeneral, userId);

    yield put({
      type: adminUserConstants.ADMIN_USERS_GET_GENERAL_SUCCESS,
      payload: { data: { ...response }, userId },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "User updated" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getDbAccessUserAdminSaga({ userId }) {
  try {
    const response = yield call(adminUsersService.getDbAccess, userId);

    yield put({
      type: adminUserConstants.ADMIN_USERS_GET_DB_ACCESS_SUCCESS,
      payload: { data: { ...response }, userId },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateDbAccessUserAdminSaga({ payload, userId }) {
  try {
    const responsePost = yield call(adminUsersService.updateDbAccess, payload);
    if (isEmpty(responsePost)) {
      return;
    }
    const response = yield call(adminUsersService.getDbAccess, userId);

    yield put({
      type: adminUserConstants.ADMIN_USERS_GET_DB_ACCESS_SUCCESS,
      payload: { data: { ...response }, userId },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "User Access updated" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getUserEntitiesAdminSaga({ userId }) {
  try {
    const data = yield call(adminUsersService.getEntitiesAccess, userId);

    let { response } = data;

    yield put({
      type: adminUserConstants.ADMIN_USERS_GET_ENTITIES_ACCESS_SUCCESS,
      payload: { userId, data: [...response] },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateUserEntitiesAdminSaga({ payload, userId }) {
  try {
    const responsePost = yield call(adminUsersService.updateEntitiesAccess, payload, userId);

    if (isEmpty(responsePost)) {
      throw new Error("Error updating entities");
    }
    const data = yield call(adminUsersService.getEntitiesAccess, userId);

    let { response } = data;

    yield put({
      type: adminUserConstants.ADMIN_USERS_GET_ENTITIES_ACCESS_SUCCESS,
      payload: { userId, data: [...response] },
    });
    yield put({
      type: alertConstants.SUCCESS,
      payload: {
        message: `${responsePost.status}`,
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* toggleEntityAccessUserAdminSaga({ userId, checked }) {
  try {
    yield call(adminUsersService.toggleEntityAccess, userId, checked);

    const response = yield call(adminUsersService.getUserGeneral, userId);

    yield put({
      type: adminUserConstants.ADMIN_USERS_GET_GENERAL_SUCCESS,
      payload: { data: { ...response }, userId },
    });

    const data = yield call(adminUsersService.getEntitiesAccess, userId);

    let { response: responseEntities } = data;

    yield put({
      type: adminUserConstants.ADMIN_USERS_GET_ENTITIES_ACCESS_SUCCESS,
      payload: { userId, data: [...responseEntities] },
    });
    yield put({
      type: alertConstants.SUCCESS,
      payload: {
        message: `Entity Access updated`,
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getUserTypesSchemaUserAdminSaga() {
  try {
    const response = yield call(adminUsersService.getUserTypes);
    yield put({
      type: adminUserConstants.ADMIN_USERS_GET_USER_TYPES_SUCCESS,
      payload: [...response],
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

// action access
// ==============

export function* getUserActionAccessSaga({ userId }) {
  try {
    // put: loading true
    yield put({
      type: adminUserConstants.ADMIN_USERS_GET_ACTION_ACCESS_LOADING,
      payload: true,
    });

    const response = yield call(adminUsersService.getUserActionAccess, userId);

    if (isEmpty(response)) {
      return;
    }

    yield put({
      type: adminUserConstants.ADMIN_USERS_GET_ACTION_ACCESS_SUCCESS,
      payload: { data: { ...response }, userId },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateUserActionAccessSaga({ payload, userId, accessTypeLOB }) {
  try {
    // put: loading true
    yield put({
      type: adminUserConstants.ADMIN_USERS_UPDATE_ACTION_ACCESS_LOADING,
      payload: true,
    });

    const responsePUT = yield call(adminUsersService.updateUserActionAccess, payload, userId, accessTypeLOB);

    if (isEmpty(responsePUT)) {
      return;
    }

    // put: loading false
    yield put({
      type: adminUserConstants.ADMIN_USERS_UPDATE_ACTION_ACCESS_LOADING,
      payload: false,
    });

    // put: loading true
    yield put({
      type: adminUserConstants.ADMIN_USERS_GET_ACTION_ACCESS_LOADING,
      payload: true,
    });

    const responseGET = yield call(adminUsersService.getUserActionAccess, userId);

    yield put({
      type: adminUserConstants.ADMIN_USERS_GET_ACTION_ACCESS_SUCCESS,
      payload: { data: { ...responseGET }, userId },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "User Action Access updated" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* deleteUserActionAccessSaga({ userId }) {
  try {
    // put: loading true
    yield put({
      type: adminUserConstants.ADMIN_USERS_DELETE_ACTION_ACCESS_LOADING,
      payload: true,
    });

    const responseDEL = yield call(adminUsersService.deleteUserActionAccess, userId);

    if (isEmpty(responseDEL)) {
      return;
    }

    // put: loading false
    yield put({
      type: adminUserConstants.ADMIN_USERS_DELETE_ACTION_ACCESS_LOADING,
      payload: false,
    });

    const responseGET = yield call(adminUsersService.getUserActionAccess, userId);

    yield put({
      type: adminUserConstants.ADMIN_USERS_GET_ACTION_ACCESS_SUCCESS,
      payload: { data: { ...responseGET }, userId },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "User Action Access deleted" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

// ==============

// status access
// ==============

export function* getUserStatusAccessSaga({ userId }) {
  try {
    // put: loading true
    yield put({
      type: adminUserConstants.ADMIN_USERS_GET_STATUS_ACCESS_LOADING,
      payload: true,
    });

    const response = yield call(adminUsersService.getUserStatusAccess, userId);

    if (isEmpty(response)) {
      return;
    }

    yield put({
      type: adminUserConstants.ADMIN_USERS_GET_STATUS_ACCESS_SUCCESS,
      payload: { data: { ...response }, userId },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateUserStatusAccessSaga({ payload, userId, LOBID, statusType }) {
  try {
    // put: loading true
    yield put({
      type: adminUserConstants.ADMIN_USERS_UPDATE_STATUS_ACCESS_LOADING,
      payload: true,
    });

    const response = yield all(
      payload.map((item) => {
        // determine if should be a delete
        // statusType 1 (claim Status) is delete if statusAccessType is 0
        // statusType 1 (claim Status (Payment)) is delete if statusAccessType is 0
        if (
          (statusType === 1 && item.statusAccessType === "0") ||
          (statusType === 2 && item.statusAccessType === "0")
        ) {
          // delete
          return call(adminUsersService.deleteUserStatusAccess, item.userStatusAccessId);
        } else {
          // update value
          return call(
            adminUsersService.updateUserStatusAccess,
            userId,
            item.statusId,
            LOBID,
            statusType,
            item.statusAccessType
          );
        }
      })
    );

    if (isEmpty(response)) {
      return;
    }

    // put: loading false
    yield put({
      type: adminUserConstants.ADMIN_USERS_UPDATE_STATUS_ACCESS_LOADING,
      payload: false,
    });

    const responseGET = yield call(adminUsersService.getUserStatusAccess, userId);

    yield put({
      type: adminUserConstants.ADMIN_USERS_GET_STATUS_ACCESS_SUCCESS,
      payload: { data: { ...responseGET }, userId },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "User Status Access updated" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

// delete ALL user status access entries
// Not to be confused with single delete, where single delete functions the same as full access (statusAccessType === "2")
export function* deleteUserStatusAccessSaga({ userId }) {
  try {
    // put: loading true
    yield put({
      type: adminUserConstants.ADMIN_USERS_DELETE_STATUS_ACCESS_LOADING,
      payload: true,
    });

    const responseDEL = yield call(adminUsersService.deleteAllUserStatusAccess, userId);

    if (isEmpty(responseDEL)) {
      return;
    }

    // put: loading false
    yield put({
      type: adminUserConstants.ADMIN_USERS_DELETE_STATUS_ACCESS_LOADING,
      payload: false,
    });

    const responseGET = yield call(adminUsersService.getUserStatusAccess, userId);

    yield put({
      type: adminUserConstants.ADMIN_USERS_GET_STATUS_ACCESS_SUCCESS,
      payload: { data: { ...responseGET }, userId },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "User Status Access deleted" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

// ==============

export function* getUserDamageInvestigatorSaga({ userId }) {
  try {
    // put: loading true
    yield put({
      type: adminUserConstants.ADMIN_USERS_DAMAGE_INVESTIGATOR_LOADING,
      payload: true,
    });

    const response = yield call(adminUsersService.getUserDamageInvestigator, userId);

    if (isEmpty(response)) {
      return;
    }

    yield put({
      type: adminUserConstants.ADMIN_USERS_DAMAGE_INVESTIGATOR_SUCCESS,
      payload: { ...response, userId },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateUserDamageInvestigatorSaga({
  userId,
  isDamageInvestigator,
  damageInvestigatorStates,
  damageInvestigatorLOB,
  superRegion,
  MidManager,
  TeamLead,
  MidManagerID,
  TeamLeadID,
  SecondTeamLeadID,
  MidBoss,
  bossID
}) {
  try {
    // put: loading true
    yield put({
      type: adminUserConstants.ADMIN_USERS_UPDATE_DAMAGE_INVESTIGATOR_LOADING,
      payload: true,
    });

    const response = yield call(
      adminUsersService.updateUserDamageInvestigator,
      userId,
      isDamageInvestigator,
      damageInvestigatorStates,
      damageInvestigatorLOB,
      superRegion,
      MidManager,
      TeamLead,
      MidManagerID,
      TeamLeadID,
      SecondTeamLeadID,
      MidBoss,
      bossID
    );

    yield put({
      type: adminUserConstants.ADMIN_USERS_UPDATE_DAMAGE_INVESTIGATOR_SUCCESS,
      payload: { response, userId },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* deleteUserDamageInvestigatorSaga({ userId }) {
  try {
    // put: loading true
    yield put({
      type: adminUserConstants.ADMIN_USERS_DELETE_DAMAGE_INVESTIGATOR_LOADING,
      payload: true,
    });

    const response = yield call(adminUsersService.deleteUserDamageInvestigator, userId);

    yield put({
      type: adminUserConstants.ADMIN_USERS_DELETE_DAMAGE_INVESTIGATOR_SUCCESS,
      payload: { response, userId },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

// ==============

export function* getUserNotificationSaga({ userId }) {
  try {
    // put: loading true
    yield put({
      type: adminUserConstants.ADMIN_USERS_NOTIFICATIONS_LOADING,
      payload: true,
    });

    const response = yield call(adminUsersService.getUserNotifications, userId);

    yield put({
      type: adminUserConstants.ADMIN_USERS_NOTIFICATIONS_SUCCESS,
      payload: { notifications: [...response], userId },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateUserNotificationSaga({ userId, notificationIds }) {
  try {
    // put: loading true
    yield put({
      type: adminUserConstants.ADMIN_USERS_UPDATE_NOTIFICATIONS_LOADING,
      payload: true,
    });

    const response = yield call(adminUsersService.updateUserNotifications, userId, notificationIds);

    yield put({
      type: adminUserConstants.ADMIN_USERS_UPDATE_NOTIFICATIONS_SUCCESS,
      payload: { response, userId },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Notifications Updated" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* deleteUserNotificationSaga({ userId }) {
  try {
    // put: loading true
    yield put({
      type: adminUserConstants.ADMIN_USERS_DELETE_NOTIFICATIONS_LOADING,
      payload: true,
    });

    const response = yield call(adminUsersService.deleteUserNotifications, userId);

    yield put({
      type: adminUserConstants.ADMIN_USERS_DELETE_NOTIFICATIONS_SUCCESS,
      payload: { response, userId },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Notifications Disabled" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* toggleClaimAssistantSaga({ isCA, userId }) {
  try {
    yield call(adminUsersService.toggleClaimAssistant, isCA, userId);

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: `${isCA ? "Enabled" : "Disabled"} Claim Assistant` },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}
