import _isEmpty from "lodash/isEmpty";

let getValuesFromEnumOptions = (enums, field_id) => {
  if (!enums || !field_id) {
    return [];
  }
  if (!enums[field_id]) {
    return [];
  }
  return enums[field_id].map((el) => el.value);
};

let getValuesFromEnumOptionsArray = (enums) => {
  if (!enums) {
    return [];
  }

  return enums.map((el) => el.value);
};

let getIdsFromEnumOptions = (enums, field_id) => {
  if (!enums || !field_id) {
    return [];
  }
  if (!enums[field_id]) {
    return [];
  }
  return enums[field_id].map((el) => el.id);
};

let getValueFromEnumOptions = (enums, field_id) => {
  if (!enums || !field_id) {
    return [];
  }
  if (!enums[field_id]) {
    return [];
  }
  return enums[field_id].map((el) => el.value);
};

let getValuesFromEnumOptionsFilter = (enums, field_id, filterVal, filterField, defaultVal, showMainAndDrop) => {
  if (!enums || !field_id || !filterVal || !filterField) {
    return [];
  }
  if (!enums[field_id]) {
    return [];
  }

  // special case for BREC. If RDA's are "Telecom" then show "Main & Drop" otherwise filter out
  if (filterField === "operation" && filterVal === "Telecom" && !showMainAndDrop) {
    return enums[field_id]
      .map((el) => (el[filterField] === filterVal || el[filterField] === defaultVal ? el.value : null))
      .filter((a) => a !== null && a !== "Main & Drop");
  }
  return enums[field_id]
    .map((el) => (el[filterField] === filterVal || el[filterField] === defaultVal ? el.value : null))
    .filter((a) => a !== null);
};

// pull list of Root cause values (IDs) filtered by LocateState also special case if a duke claim
const getRootCauseValueFromEnumOptions = (enums, field_id, state, entityId, superRegionID) => {
  if (!enums || !field_id) {
    return [];
  }

  if (!enums[field_id]) {
    return [];
  }

  // Check for special condition where we need to include null states
  const shouldIncludeNullStates = entityId === 43 && superRegionID !== 4;

  return enums[field_id]
    .filter((e) => {
      // Include items that match the state OR
      // items with null state if special condition is met
      return e.state === state || (shouldIncludeNullStates && e.state === null);
    })
    .map((el) => el.value);
};

let getLabelFromEnumOptions = (enums, field_id) => {
  if (!enums || !field_id) {
    return [];
  }
  if (!enums[field_id]) {
    return [];
  }
  return enums[field_id].map((el) => el.label);
};

let buildRules = (claim, entityId, formControlEntity, formControlRole, formLocked) => {
  // Function: build rules object for specific field
  const buildRulesForField = (fieldId) => {
    // define default rules for this fieldId
    const fieldRules = {
      isEditable: !formLocked,
      isRequired: false,
      isViewable: true,
    };

    // 1. First, check if there is an fieldId entry in the entity rules object (FIELD HAS AT LEAST ONE ENTITY RULE)
    // (entity rules are stored in redux permission by fieldId and then entityId because it must be determined when the claim loads / in this function)
    if (formControlEntity && fieldId in formControlEntity && !_isEmpty(formControlEntity[fieldId])) {
      // 2. Check if there is an entity entry inside the fieldId object (HAS ENTITY RULE FOR THIS ENTITY)
      if (entityId in formControlEntity[fieldId]) {
        // 3. if there is, use the isviewable and isRequired fields from that object
        fieldRules.isRequired = Boolean(formControlEntity[fieldId][entityId].isRequired);
        fieldRules.isViewable = Boolean(formControlEntity[fieldId][entityId].isViewable);

        // 4. Then, check if that object contains a roleFormFieldId (HAS ENTITY AND ROLE RULES - objects were merged)
        if (formControlEntity[fieldId][entityId].roleFormFieldId !== null && !formLocked) {
          // 5. if it does contain that field value, then use the "isEditable" value
          fieldRules.isEditable = Boolean(formControlEntity[fieldId][entityId].isEditable);
        }
        // 6. If it does not contain that field value, then do nothing (fall back to default which is true)
      }
    }

    // 7. If No entity rule exists in the array (object did not have "entityFormFieldId" - filtered in permission reducer)
    // 8. Then check if a role rule exists (HAS ROLE RULE ONLY)
    // (role rules are stored in redux permission by fieldId, because there will only ever be one role per login)
    else if (formControlRole && fieldId in formControlRole) {
      // 9. if it does, then use the isEditable and isVieable values from that object
      if (!formLocked) fieldRules.isEditable = Boolean(formControlRole[fieldId].isEditable);
      fieldRules.isViewable = Boolean(formControlRole[fieldId].isViewable);
    }

    // return final rules for field
    return fieldRules;
  };

  let allClaimFieldRules = {};

  // get fields for each Claim.Claim (Sections)
  let claimFieldRules = Object.keys(claim["Claim"]).reduce((obj, section) => {
    // fields in section
    const fieldIdKeysObj = Object.keys(claim["Claim"][section]).reduce((o, fieldId) => {
      const fieldRules = buildRulesForField(fieldId);

      return { ...o, [fieldId]: fieldRules };
    }, {});

    return { ...obj, ...fieldIdKeysObj };
  }, {});

  allClaimFieldRules = { ...allClaimFieldRules, ...claimFieldRules };

  // get fields for each Claim.paymentFormJSON (this is the only section that is not within the Claim object)
  if (claim["paymentFormJSON"]) {
    let paymentFieldRules = Object.keys(claim["paymentFormJSON"]).reduce((obj, fieldId) => {
      const fieldRules = buildRulesForField(fieldId);

      return { ...obj, [fieldId]: fieldRules };
    }, {});

    // merge rules
    allClaimFieldRules = { ...claimFieldRules, ...paymentFieldRules };
  }

  return allClaimFieldRules;
};

let buildLabels = (claim, entityId, formControlEntity) => {
  // Function: get alias (if exists) for specific field
  const getAliasForField = (fieldId) => {
    let alias = null;

    // 1. First, check if there is an fieldId entry in the entity rules object (FIELD HAS AT LEAST ONE ENTITY RULE)
    // (entity rules are stored in redux permission by fieldId and then entityId because it must be determined when the claim loads / in this function)
    if (formControlEntity && fieldId in formControlEntity) {
      // 2. Check if there is an entity entry inside the fieldId object (HAS ENTITY RULE FOR THIS ENTITY)
      if (entityId in formControlEntity[fieldId]) {
        // 3. if there is, use the alias field from that object (could be null)
        alias = formControlEntity[fieldId][entityId].alias;
      }
    }

    // return alias for field
    return alias;
  };

  // get fields for each Claim.Claim (Sections)
  let labels_immutable_claim = Object.keys(claim["Claim"])
    .filter((sec) => sec !== "Meta")
    .reduce((prev, section) => {
      // fields in section
      let sectionFieldLabels = Object.keys(claim["Claim"][section]).reduce((prev_inner, fieldId) => {
        const alias = getAliasForField(fieldId);
        // if alias was found, use alias as label
        const label = alias ? alias : claim["Claim"][section][fieldId]["label"];
        return { ...prev_inner, [fieldId]: label };
      }, {});

      return { ...prev, ...sectionFieldLabels };
    }, {});

  if (claim["paymentFormJSON"]) {
    let paymentFormLabels = Object.keys(claim["paymentFormJSON"]).reduce((prev, fieldId) => {
      const alias = getAliasForField(fieldId);
      // if alias was found, use alias as label
      const label = alias ? alias : claim["paymentFormJSON"][fieldId]["label"];
      return { ...prev, [fieldId]: label };
    }, {});
    return { ...paymentFormLabels, ...labels_immutable_claim };
  }

  return labels_immutable_claim;
};

let buildEnums = (claim) => {
  if (claim["Claim"]) {
    // update claim
    let enum_options_claim = Object.keys(claim["Claim"])
      .filter((sec) => sec !== "Meta")
      .reduce((prev, section) => {
        let sectionFieldEnumOptions = Object.keys(claim["Claim"][section]).reduce((prev_inner, key_inner) => {
          if (claim["Claim"][section][key_inner]["type"]["enum"] === true) {
            return {
              ...prev_inner,
              [key_inner]: claim["Claim"][section][key_inner]["type"]["enumOptions"],
            };
          }
          return {
            ...prev_inner,
            [key_inner]: false,
          };
        }, {});

        return { ...prev, ...sectionFieldEnumOptions };
      }, {});

    if (claim["paymentFormJSON"]) {
      let enum_options_payment = Object.keys(claim["paymentFormJSON"]).reduce((prev, el) => {
        if (claim["paymentFormJSON"][el]["type"]["enum"] === true) {
          return {
            ...prev,
            [el]: claim["paymentFormJSON"][el]["type"]["enumOptions"],
          };
        }
        return { ...prev, [el]: false };
      }, {});
      return { ...enum_options_payment, ...enum_options_claim };
    }
    return enum_options_claim;
  }
  // create claim

  let enum_options_claim = Object.keys(claim)
    .filter((sec) => sec !== "Meta")
    .reduce((prev, section) => {
      let sectionFieldEnumOptions = Object.keys(claim[section]).reduce((prev_inner, key_inner) => {
        if (claim[section][key_inner]["type"]["enum"] === true) {
          return {
            ...prev_inner,
            [key_inner]: claim[section][key_inner]["type"]["enumOptions"],
          };
        }
        return {
          ...prev_inner,
          [key_inner]: false,
        };
      }, {});

      return { ...prev, ...sectionFieldEnumOptions };
    }, {});

  return enum_options_claim;
};

let buildHydration = (claim) => {
  let hydrationValues_immutable_claim = Object.keys(claim["Claim"])
    .filter((sec) => sec !== "Meta")
    .reduce((prev, section) => {
      let sectionFieldValues = Object.keys(claim["Claim"][section]).reduce((prev_inner, key_inner) => {
        let value = claim["Claim"][section][key_inner]["value"];

        return {
          ...prev_inner,
          [key_inner]: value,
        };
      }, {});

      return { ...prev, ...sectionFieldValues };
    }, {});

  if (claim["paymentFormJSON"]) {
    let hydration_values_payment = Object.keys(claim["paymentFormJSON"]).reduce((prev, el) => {
      let value = claim["paymentFormJSON"][el]["value"];

      return { ...prev, [el]: value };
    }, {});
    return { ...hydration_values_payment, ...hydrationValues_immutable_claim };
  }

  return hydrationValues_immutable_claim;
};

let optionSchemaFormat = (enums, id) => {
  if (!enums || !id) {
    return {};
  }
  if (!enums[id]) {
    return {};
  }

  return enums[id].reduce((prev, el) => {
    return {
      ...prev,
      [el["value"]]: { label: el["label"], value: el["value"] },
    };
  }, {});
};

let optionSchemaFormatFilter = (enums, id, filterVal, filterField, defaultVal) => {
  if (!enums || !id) {
    return {};
  }
  if (!enums[id]) {
    return {};
  }

  return enums[id].reduce((prev, el) => {
    if (el[filterField] !== filterVal && el[filterField] !== defaultVal) return { ...prev };
    else
      return {
        ...prev,
        [el["value"]]: { label: el["label"], value: el["value"] },
      };
  }, {});
};

let optionSchemaFormat_label = (enums, id) => {
  if (!enums || !id) {
    return {};
  }
  if (!enums[id]) {
    return {};
  }

  return enums[id].reduce((prev, el) => {
    return {
      ...prev,
      [el["value"]]: { label: el["label"], value: el["value"] },
    };
  }, {});
};

let optionSchemaFormatArray = (enums) => {
  if (!enums) {
    return {};
  }

  return enums.reduce((prev, el) => {
    return {
      ...prev,
      [el["value"]]: { label: el["label"], value: el["value"] },
    };
  }, {});
};

let filterPropertyByValue = (list, key, value) => {
  if (!list || !key || !value) return;

  let result = list.filter((el) => el[key] === value);
  return result;
};

let getUniquePropertyValueList = (list, property) => {
  // returns a list of unique values listed for the the list at the target_property,
  // returns -> array
  if (!list || !property) return;
  let result = list.reduce((prev, el) => [...prev, el[property]], []);
  return [...new Set(result)];
};

let buildSchema = (list, labelKey, valueKey) => {
  if (!list || !labelKey || !valueKey) return;

  let regionSchema = list.reduce((prev, el) => {
    let x = Object.keys(prev).find((key) => key === el[valueKey]);
    if (!x) {
      return {
        ...prev,
        [el[valueKey]]: { label: el[labelKey], value: el[valueKey] },
      };
    }
    return { ...prev };
  }, {});

  return regionSchema;
};

// Payment Model 2

let paymentFormPPFactor_2 = (checkAmount, invoiceAmount) => {
  if (checkAmount === 0 || invoiceAmount === 0) return 0;
  let amount = Number(checkAmount / invoiceAmount).toFixed(6);
  return Number(amount);
};

let third_party_percentage_of_payment_amount_2 = (third_party_fee_inner, check_amount_inner) => {
  if (third_party_fee_inner === 0 || check_amount_inner === 0) return 0;
  let amount = Number(third_party_fee_inner / check_amount_inner).toFixed(6);

  return Number(amount);
};

let paymentFormPrgOwed_2 = (
  third_party_fee,
  check_amount,
  line_item_1,
  pp_factor,
  credit_card_fee_amount,
  prg_charge
) => {
  let third_party_percentage_of_payment_amount = third_party_percentage_of_payment_amount_2(
    third_party_fee,
    check_amount
  );

  if (third_party_percentage_of_payment_amount > 0) {
    let amount = Number(
      (check_amount - line_item_1 * pp_factor) * prg_charge +
        line_item_1 * pp_factor * (1 - third_party_percentage_of_payment_amount) +
        credit_card_fee_amount
    ).toFixed(2);

    return Number(amount);
  }

  let amount = Number(
    (check_amount - line_item_1 * pp_factor) * prg_charge + line_item_1 * pp_factor + credit_card_fee_amount
  ).toFixed(2);

  return Number(amount);
};

let paymentFormClientOwed_2 = (check_amount, prg_owed, third_party_fee) => {
  let result = Number(check_amount - prg_owed - third_party_fee).toFixed(2);
  return Number(result);
};

let paymentFormPrgOwedBreakdown_2 = (
  third_party_fee,
  check_amount,
  line_item_1,
  pp_factor,
  invoice_amount,
  prg_charge,
  credit_card_fee_amount,
  prg_owed
) => {
  if (check_amount === 0) return "";

  let thirdPartyPercentageOfPaymentAmount = Number(third_party_fee / check_amount).toFixed(6);

  let third_party_percentage_of_payment_amount = Number(thirdPartyPercentageOfPaymentAmount);

  let one = Number(line_item_1 * pp_factor * (1 - third_party_percentage_of_payment_amount)).toFixed(2);

  let two = Number((check_amount - (line_item_1 * check_amount) / invoice_amount) * prg_charge).toFixed(2);

  return `Prg owed: $${one} + $${two} + $${credit_card_fee_amount} = $${prg_owed}`;
};

let paymentFormLineItemDue_2 = (line_item_1, pp_factor) => {
  if (line_item_1 === 0 || pp_factor === 0) return "";

  return `Line Item: $${line_item_1} x ${pp_factor} = $${line_item_1 * pp_factor}`;
};

let paymentFormCreditCardRecoup_2 = (credit_card_fee_amount) => {
  if (credit_card_fee_amount === 0) return "";

  return `Credit Card Recoup: $${Number(credit_card_fee_amount).toFixed(2)}`;
};

let payoutModelTwo = (invoiceAmount, checkAmount, thirdPartyFee, lineItem1, creditCardFeeAmount, PrgCharge) => {
  let check_amount = Number(!checkAmount ? 0 : checkAmount);

  let invoice_amount = Number(!invoiceAmount ? 0 : invoiceAmount);

  let third_party_fee = Number(thirdPartyFee ? thirdPartyFee : 0);

  let line_item_1 = Number(lineItem1 ? lineItem1 : 0);

  // @TODO
  // if (entityId = 23 && myccyesno == 2) {
  //   CCFeeAmount.value = Number(Number(cBase) * 0.03) .toFixed(2);
  // }
  let credit_card_fee_amount = Number(creditCardFeeAmount ? creditCardFeeAmount : 0);
  let prg_charge = Number(PrgCharge ? PrgCharge : 0);

  let pp_factor = paymentFormPPFactor_2(check_amount, invoice_amount);

  let prg_owed = paymentFormPrgOwed_2(
    third_party_fee,
    check_amount,
    line_item_1,
    pp_factor,
    credit_card_fee_amount,
    prg_charge
  );

  let client_owed = paymentFormClientOwed_2(check_amount, prg_owed, third_party_fee);

  let prg_owed_breakdown = paymentFormPrgOwedBreakdown_2(
    third_party_fee,
    check_amount,
    line_item_1,
    pp_factor,
    invoice_amount,
    prg_charge,
    credit_card_fee_amount,
    prg_owed
  );

  let line_item_breakdown = paymentFormLineItemDue_2(line_item_1, pp_factor);

  let credit_card_recoup_breakdown = paymentFormCreditCardRecoup_2(credit_card_fee_amount);

  return [pp_factor, prg_owed, client_owed, prg_owed_breakdown, line_item_breakdown, credit_card_recoup_breakdown];
};

let paymentFormPPFactor_1 = (check_amount, invoice_amount) => {
  if (check_amount === 0 || invoice_amount === 0) return 0;
  let amount = Number(check_amount / invoice_amount).toFixed(6);
  return Number(amount);
};

let paymentFormPrgOwed_1 = (check_amount, pp_factor, credit_card_fee_amount, prg_charge) => {
  let prg_owed = Number(
    (check_amount - 0.0 * pp_factor) * prg_charge + 0.0 * pp_factor + credit_card_fee_amount
  ).toFixed(2);

  return Number(prg_owed);
};

let paymentFormClientOwed_1 = (check_amount, prg_owed, third_party_fee) => {
  let result = Number(check_amount - prg_owed - third_party_fee).toFixed(2);

  return Number(result);
};

let paymentFormPrgOwedBreakdown_1 = (check_amount, pp_factor, prg_charge, credit_card_fee_amount, prg_owed) => {
  if (check_amount === 0) return "";

  let one = Number(check_amount * pp_factor).toFixed(2);

  return `Prg owed: $${one} * ${Number(prg_charge).toFixed(2)} + ${Number(credit_card_fee_amount).toFixed(
    2
  )} = $${Number(prg_owed).toFixed(2)}`;
};

let paymentFormLineItemDue_1 = (line_item_1, pp_factor) => {
  if (line_item_1 === 0 || pp_factor === 0) return "";

  return "Line Item: Does Not Factor In - Straight % Formula";
};

let paymentFormCreditCardRecoup_1 = (credit_card_fee_amount) => {
  if (credit_card_fee_amount === 0) return "";

  return `Credit Card Recoup: $${Number(credit_card_fee_amount).toFixed(2)}`;
};

let payoutModelOne = (invoiceAmount, checkAmount, thirdPartyFee, lineItem1, creditCardFeeAmount, PrgCharge) => {
  let check_amount = Number(!checkAmount ? 0 : checkAmount);

  let invoice_amount = Number(!invoiceAmount ? 0 : invoiceAmount);

  let third_party_fee = Number(thirdPartyFee ? thirdPartyFee : 0);

  let line_item_1 = Number(lineItem1 ? lineItem1 : 0);

  // @TODO
  // if (entityId = 23 && myccyesno == 2) {
  //   CCFeeAmount.value = Number(Number(cBase) * 0.03) .toFixed(2);
  // }
  let credit_card_fee_amount = Number(creditCardFeeAmount ? creditCardFeeAmount : 0);
  let prg_charge = Number(PrgCharge ? PrgCharge : 0);

  let pp_factor = paymentFormPPFactor_1(check_amount, invoice_amount);

  let prg_owed = paymentFormPrgOwed_1(check_amount, pp_factor, credit_card_fee_amount, prg_charge);

  let client_owed = paymentFormClientOwed_1(check_amount, prg_owed, third_party_fee);

  let prg_owed_breakdown = paymentFormPrgOwedBreakdown_1(
    check_amount,
    pp_factor,
    prg_charge,
    credit_card_fee_amount,
    prg_owed
  );

  let line_item_breakdown = paymentFormLineItemDue_1(line_item_1, pp_factor);

  let credit_card_recoup_breakdown = paymentFormCreditCardRecoup_1(credit_card_fee_amount);

  return [pp_factor, prg_owed, client_owed, prg_owed_breakdown, line_item_breakdown, credit_card_recoup_breakdown];
};

//payment model 3:  239

let paymentFormPPFactor_3 = (checkAmount, invoiceAmount) => {
  if (checkAmount === 0 || invoiceAmount === 0) return 0;
  let amount = Number(checkAmount / invoiceAmount).toFixed(6);
  return Number(amount);
};

let paymentFormPrgOwed_3 = (check_amount, pp_factor, credit_card_fee_amount, prg_charge) => {
  let result = Number(
    Number(
      Number(Number(check_amount - Number(0.0 * pp_factor)) * prg_charge) + Number(Number(0.0) * Number(pp_factor))
    ) + Number(credit_card_fee_amount)
  ).toFixed(2);
  return result;
};

let paymentFormClientOwed_3 = (check_amount, prg_owed, third_party_fee) => {
  let result = Number(check_amount - prg_owed - third_party_fee).toFixed(2);

  return Number(result);
};

let paymentFormPrgOwedBreakdown_3 = (check_amount, pp_factor, prg_charge, credit_card_fee_amount, prg_owed) => {
  if (check_amount === 0) return "";

  let one = Number(check_amount * pp_factor).toFixed(2);

  return `Prg owed: $${one} * ${Number(prg_charge).toFixed(2)} + ${Number(credit_card_fee_amount).toFixed(
    2
  )} = $${Number(prg_owed).toFixed(2)}`;
};

let paymentFormLineItemDue_3 = (line_item_1, pp_factor) => {
  if (line_item_1 === 0 || pp_factor === 0) return "";

  return "Line Item: Does Not Factor In - Straight % Formula";
};

let paymentFormCreditCardRecoup_3 = (credit_card_fee_amount) => {
  if (credit_card_fee_amount === 0) return "";

  return `Credit Card Recoup: $${Number(credit_card_fee_amount).toFixed(2)}`;
};

let payoutModelThree = (invoiceAmount, checkAmount, thirdPartyFee, lineItem1, creditCardFeeAmount, PrgCharge) => {
  let check_amount = Number(!checkAmount ? 0 : checkAmount);

  let invoice_amount = Number(!invoiceAmount ? 0 : invoiceAmount);

  let third_party_fee = Number(thirdPartyFee ? thirdPartyFee : 0);

  let line_item_1 = Number(lineItem1 ? lineItem1 : 0);

  // @TODO
  // if (#pullthisclaiminfo.entityid# eq 999) {
  //   CCFeeAmount.value = Number(Number(cBase) * 0.03) .toFixed(2);
  // }
  let credit_card_fee_amount = Number(creditCardFeeAmount ? creditCardFeeAmount : 0);
  let prg_charge = Number(PrgCharge ? PrgCharge : 0);

  let pp_factor = paymentFormPPFactor_3(check_amount, invoice_amount);

  let prg_owed = paymentFormPrgOwed_3(check_amount, pp_factor, credit_card_fee_amount, prg_charge);

  let client_owed = paymentFormClientOwed_3(check_amount, prg_owed, third_party_fee);

  let prg_owed_breakdown = paymentFormPrgOwedBreakdown_3(
    check_amount,
    pp_factor,
    prg_charge,
    credit_card_fee_amount,
    prg_owed
  );

  let line_item_breakdown = paymentFormLineItemDue_3(line_item_1, pp_factor);

  let credit_card_recoup_breakdown = paymentFormCreditCardRecoup_3(credit_card_fee_amount);

  return [pp_factor, prg_owed, client_owed, prg_owed_breakdown, line_item_breakdown, credit_card_recoup_breakdown];
};

let paymentFormPPFactor_40 = (checkAmount, invoiceAmount) => {
  if (checkAmount === 0 || invoiceAmount === 0) return 0;
  let amount = Number(checkAmount / invoiceAmount).toFixed(6);
  return Number(amount);
};

let paymentFormPrgOwed_40 = (check_amount, pp_factor, credit_card_fee_amount, prg_charge) => {
  let result = Number(
    Number(
      Number(Number(Number(check_amount) - Number(0.0 * Number(pp_factor))) * prg_charge) +
        Number(0.0 * Number(pp_factor))
    ) + Number(credit_card_fee_amount)
  ).toFixed(2);

  return Number(result);
};

let paymentFormClientOwed_40 = (check_amount, prg_owed, third_party_fee) => {
  let result = Number(check_amount - prg_owed - third_party_fee).toFixed(2);

  return Number(result);
};

let paymentFormPrgOwedBreakdown_40 = (check_amount, pp_factor, prg_charge, credit_card_fee_amount, prg_owed) => {
  if (check_amount === 0) return "";

  let one = Number(check_amount * pp_factor).toFixed(2);

  return `Prg owed: $${one} * ${Number(prg_charge).toFixed(2)} + ${Number(credit_card_fee_amount).toFixed(
    2
  )} = $${Number(prg_owed).toFixed(2)}`;
};

let paymentFormLineItemDue_40 = (line_item_1, pp_factor) => {
  if (line_item_1 === 0 || pp_factor === 0) return "";

  return "Line Item: Does Not Factor In - Straight % Formula";
};

let paymentFormCreditCardRecoup_40 = (credit_card_fee_amount) => {
  if (credit_card_fee_amount === 0) return "";

  return `Credit Card Recoup: $${Number(credit_card_fee_amount).toFixed(2)}`;
};

let payoutModelForty = (invoiceAmount, checkAmount, thirdPartyFee, lineItem1, creditCardFeeAmount, PrgCharge) => {
  let check_amount = Number(!checkAmount ? 0 : checkAmount);

  let invoice_amount = Number(!invoiceAmount ? 0 : invoiceAmount);

  let third_party_fee = Number(thirdPartyFee ? thirdPartyFee : 0);

  let line_item_1 = Number(lineItem1 ? lineItem1 : 0);

  // @TODO
  // if ( #pullthisclaiminfo.entityid# eq 23) {
  //   CCFeeAmount.value = Number(Number(cBase) * 0.03) .toFixed(2);
  // }
  let credit_card_fee_amount = Number(creditCardFeeAmount ? creditCardFeeAmount : 0);
  let prg_charge = Number(PrgCharge ? PrgCharge : 0);

  let pp_factor = paymentFormPPFactor_40(check_amount, invoice_amount);

  let prg_owed = paymentFormPrgOwed_40(check_amount, pp_factor, credit_card_fee_amount, prg_charge);

  let client_owed = paymentFormClientOwed_40(check_amount, prg_owed, third_party_fee);

  let prg_owed_breakdown = paymentFormPrgOwedBreakdown_40(
    check_amount,
    pp_factor,
    prg_charge,
    credit_card_fee_amount,
    prg_owed
  );

  let line_item_breakdown = paymentFormLineItemDue_40(line_item_1, pp_factor);

  let credit_card_recoup_breakdown = paymentFormCreditCardRecoup_40(credit_card_fee_amount);

  return [pp_factor, prg_owed, client_owed, prg_owed_breakdown, line_item_breakdown, credit_card_recoup_breakdown];
};

let paymentFormPPFactor_1_cc = (check_amount, invoice_amount) => {
  if (check_amount === 0 || invoice_amount === 0) return 0;
  let amount = Number(check_amount / invoice_amount).toFixed(6);
  return Number(amount);
};

let paymentFormPrgOwed_1_cc = (
  check_amount,

  credit_card_fee_amount,
  prg_charge
) => {
  let prg_owed = Number((check_amount - credit_card_fee_amount) * prg_charge).toFixed(2);

  return Number(prg_owed);
};

let paymentFormClientOwed_1_cc = (check_amount, prg_owed, credit_card_fee_amount, third_party_fee) => {
  let result = Number(check_amount - prg_owed - credit_card_fee_amount - third_party_fee).toFixed(2);

  return Number(result);
};

let paymentFormPrgOwedBreakdown_1_cc = (check_amount, prg_charge, credit_card_fee_amount, prg_owed) => {
  if (check_amount === 0) return "";

  return `(Prg owed: $${Number(check_amount).toFixed(
    2
  )} - $${credit_card_fee_amount} (cc fee) ) * ${prg_charge} = $${Number(prg_owed).toFixed(2)}`;
};

let paymentFormLineItemDue_1_cc = (line_item_1, pp_factor) => {
  if (pp_factor === 0) return "";

  return "Line Item: Does Not Factor In - Straight % Formula";
};

let paymentFormCreditCardRecoup_1_cc = (credit_card_fee_amount) => {
  if (credit_card_fee_amount === 0) return "";

  return `Credit Card Recoup: $${Number(credit_card_fee_amount).toFixed(2)}`;
};

let getBalanceDue = (prg_owed, client_owed) => {
  if (prg_owed === 0 && client_owed === 0) return "";
  return `Balance Due: ${(prg_owed + client_owed).toFixed(2)} `;
};

// let getTotalNoCC = (prg_owed, client_owed) => {
//   // getTotalNoCC// document.getElementById('totalNoCC').value = Number(PShare.value) + Number(CShare.value);
//   if (prg_owed === 0 && client_owed === 0) return "";
//   return `Total Without Credit Card: $${prg_owed + client_owed}`;
// };

// let getAttAmt = (check_amount, credit_card_fee_amount) => {
//   // attAmt.value = Number(cBase) - Number(CCFeeAmount.value);
//   if (!check_amount) return "";
//   return `${check_amount - credit_card_fee_amount}`;
// };

let payoutModelOneCC = (invoiceAmount, checkAmount, thirdPartyFee, lineItem1, creditCardFeeAmount, PrgCharge) => {
  let check_amount = Number(!checkAmount ? 0 : checkAmount);

  let invoice_amount = Number(!invoiceAmount ? 0 : invoiceAmount);
  // let invoice_amount = 874.04;

  let third_party_fee = Number(thirdPartyFee ? thirdPartyFee : 0);

  let line_item_1 = Number(lineItem1 ? lineItem1 : 0);

  // @TODO
  // if (myccyesno == 2 && atCC > 0 ) {
  //   CCFeeAmount.value = Number(Number(cBase) - (Number(cBase) / 1.03)).toFixed(2);
  // }

  let credit_card_fee_amount = Number(creditCardFeeAmount ? creditCardFeeAmount : 0);

  let prg_charge = Number(PrgCharge ? PrgCharge : 0);
  // let prg_charge = 0.17;

  let pp_factor = paymentFormPPFactor_1_cc(check_amount, invoice_amount);

  let prg_owed = paymentFormPrgOwed_1_cc(check_amount, credit_card_fee_amount, prg_charge);

  let client_owed = paymentFormClientOwed_1_cc(check_amount, prg_owed, credit_card_fee_amount, third_party_fee);

  let prg_owed_breakdown = paymentFormPrgOwedBreakdown_1_cc(check_amount, prg_charge, credit_card_fee_amount, prg_owed);

  let line_item_breakdown = paymentFormLineItemDue_1_cc(line_item_1, pp_factor);

  let credit_card_recoup_breakdown = paymentFormCreditCardRecoup_1_cc(credit_card_fee_amount);

  let balance_due = getBalanceDue(prg_owed, client_owed);

  // let total_no_credit_card = getTotalNoCC(prg_owed, client_owed);
  // let att_amt = getAttAmt(check_amount, credit_card_fee_amount);

  return [
    pp_factor,
    prg_owed,
    client_owed,
    prg_owed_breakdown,
    line_item_breakdown,
    credit_card_recoup_breakdown,
    balance_due,
  ];
};
export const claim_util = {
  getValuesFromEnumOptions,
  getValuesFromEnumOptionsFilter,
  getValuesFromEnumOptionsArray,
  getIdsFromEnumOptions,
  getLabelFromEnumOptions,
  buildRules,
  buildLabels,
  filterPropertyByValue,
  buildEnums,
  buildSchema,
  buildHydration,
  optionSchemaFormat,
  optionSchemaFormatFilter,
  optionSchemaFormat_label,
  optionSchemaFormatArray,
  getValueFromEnumOptions,
  getUniquePropertyValueList,
  payoutModelOne,
  payoutModelTwo,
  payoutModelThree,
  payoutModelForty,
  payoutModelOneCC,

  getRootCauseValueFromEnumOptions,
};
