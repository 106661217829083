import { receiptMakerConstants } from "_constants";
import { REHYDRATE } from "redux-persist/lib/constants";
import { produce } from "immer";

const initialState = {
  receipts: [],
  heathBatchReceipts: [],
  usicReceipts: [],
  snailMailReceipts: [],
  sendEmailData: [],
  loading: false,
  error: null,
};

export function receiptMaker(state = initialState, action) {
  const { type, payload, error } = action;

  return produce(state, (draft) => {
    switch (type) {
      case receiptMakerConstants.GET_V3_RECEIPTS_REQUEST:
      case receiptMakerConstants.GET_SPECIAL_RECEIPTS_REQUEST:
      case receiptMakerConstants.GET_SEND_EMAIL_DATA_REQUEST:
        draft.loading = true;
        draft.error = null;
        break;
      case receiptMakerConstants.GET_V3_RECEIPTS_SUCCESS:
        draft.loading = false;
        draft.receipts = payload.receipts;
        break;
      case receiptMakerConstants.GET_SPECIAL_RECEIPTS_SUCCESS:
        draft.loading = false;
        draft.heathBatchReceipts = payload.heathBatchReceipts;
        draft.usicReceipts = payload.usicReceipts;
        draft.snailMailReceipts = payload.snailMailReceipts;
        break;
      case receiptMakerConstants.GET_SEND_EMAIL_DATA_SUCCESS:
        draft.loading = false;
        draft.sendEmailData = payload.sendEmailEntries;
        break;
      case receiptMakerConstants.GET_V3_RECEIPTS_FAILURE:
      case receiptMakerConstants.GET_SPECIAL_RECEIPTS_FAILURE:
      case receiptMakerConstants.GET_SEND_EMAIL_DATA_FAILURE:
        draft.loading = false;
        draft.error = error;
        break;
      case REHYDRATE:
        return payload && payload.admin && payload.admin.receiptMaker
          ? { ...payload.admin.receiptMaker }
          : initialState;
      default:
        return state;
    }
  });
}
