import { receiptMakerConstants } from "_constants";

const getV3Receipts = (startDate, endDate) => ({
  type: receiptMakerConstants.GET_V3_RECEIPTS_REQUEST,
  payload: { startDate, endDate },
});

const getV3ReceiptsSuccess = (payload) => ({
  type: receiptMakerConstants.GET_V3_RECEIPTS_SUCCESS,
  payload,
});

const getV3ReceiptsFailure = (error) => ({
  type: receiptMakerConstants.GET_V3_RECEIPTS_FAILURE,
  error,
});

const getSpecialReceipts = (startDate, endDate) => ({
  type: receiptMakerConstants.GET_SPECIAL_RECEIPTS_REQUEST,
  payload: { startDate, endDate },
});

const getSpecialReceiptsSuccess = (payload) => ({
  type: receiptMakerConstants.GET_SPECIAL_RECEIPTS_SUCCESS,
  payload,
});

const getSpecialReceiptsFailure = (error) => ({
  type: receiptMakerConstants.GET_SPECIAL_RECEIPTS_FAILURE,
  error,
});

const getSendEmailData = (startDate, endDate) => ({
  type: receiptMakerConstants.GET_SEND_EMAIL_DATA_REQUEST,
  payload: { startDate, endDate },
});

const getSendEmailDataSuccess = (payload) => ({
  type: receiptMakerConstants.GET_SEND_EMAIL_DATA_SUCCESS,
  payload,
});

const getSendEmailDataFailure = (error) => ({
  type: receiptMakerConstants.GET_SEND_EMAIL_DATA_FAILURE,
  error,
});

export const adminReceiptMakerActions = {
  getV3Receipts,
  getV3ReceiptsSuccess,
  getV3ReceiptsFailure,
  getSpecialReceipts,
  getSpecialReceiptsSuccess,
  getSpecialReceiptsFailure,
  getSendEmailData,
  getSendEmailDataSuccess,
  getSendEmailDataFailure,
};
