import React from "react";
// import TextField from "@material-ui/core/TextField"; // v4
// import Autocomplete from "@material-ui/lab/Autocomplete"; // v4
import Autocomplete from "@mui/material/Autocomplete"; // v5
import TextField from "@mui/material/TextField"; // v5
import { Controller } from "react-hook-form";
import theme from "_theme/theme";
import { useTheme } from "@material-ui/core/styles";
import _ from "lodash/fp";
import Popper from "@material-ui/core/Popper";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  popper: {
    width: "fit-content",
  },
});

const PopperComp = function (props) {
  return <Popper {...props} style={styles.popper} placement="bottom-start" />;
};

// Prop: darkMode
// default: false
// type: boolean
// Info: Activates dark grey background

// props: options
// default: null
// type: array
// []:string || []:int
// note: if a optionsSchema is provided where the label and value are different. then the options should be a list of the values in the optionsschema.
// The component takes a value from options and uses that value to do a hash look up on the optionSchema
// example: options={[1,2,3]}

// prop: optionSchema
// default: undefined
// type: object
// info:  {[id]: {label: string, value: id}}
// example:
// optionSchema={{
//   1: {value: 1, label: "Telecom"},
//   2: {value: 2, label: "Power & Gas"},
//   3: {value: 3, label: "Frelo"}
// }}

// prop: fitContentPopper
// default: undefined
// type: boolean
// required: false
// info: allows the popper to be wider than the autocomplete input field

function Index({
  disabled,
  options,
  label,
  control,
  name,
  defaultValue = "",
  darkMode,
  placeholder,
  unlock,
  autoSelect,
  noOptionsText,
  optionSchema,
  fitContentPopper,
  testId,
  maxLength,
  error,
  helperText,
  disableOption,
  variant,
  fullWidth,
  className,
  required,
  onKeyDown,
  style,
  size,
  sx,
  customValidationError,
  onChange
}) {
  const theme = useTheme();

  return (
    <Controller
    rules={{
      validate: (value) => {
        if (required && !value) {
          return customValidationError ?? "Required"
        }
        return true;
      },
    }}
      render={(props) => {
        // @TODO
        // BUG: autocomplete default value not updating if its already been rendered
        // look at select_ for reference on using the default value if not value
        return (
          <Autocomplete
            {...props}
            // color="primary"
            size={size ? size : "small"}
            style={style}
            value={(props.value !== null || typeof props.value !== 'undefined') ? props.value : defaultValue}
            data-testid={testId ? testId : ""}
            options={options && options ? options : []}
            getOptionLabel={(option) => {
              if (optionSchema && optionSchema[option]) {
                return `${optionSchema[option]["label"]}`;
              }
              if (option.label) return option.label;
              return `${option}`;
            }}
            onKeyDown={onKeyDown}
            // getOptionSelected={(option, value) => {
            //   return _.isEqual(option, value) || option;
            // }} // v4 prop
            isOptionEqualToValue={(option, value) => {
              return _.isEqual(option, value) || option;
            }} // v5 prop
            getOptionDisabled={(option) => {
              return disableOption?.includes(option);
            }}
            PopperComponent={fitContentPopper ? PopperComp : undefined}
            disabled={disabled ? disabled : null}
            blurOnSelect
            fullWidth={fullWidth ? fullWidth : null}
            noOptionsText={noOptionsText ? noOptionsText : ""}
            freeSolo={unlock ? unlock : false}
            autoSelect={autoSelect ? autoSelect : false}
            sx={{
              ...sx, // bring in any prop styles
              label: {
                transform: "translate(14px, -9px) scale(0.75) !important", // fix for this component while Mui V4 is installed (v4 causes slight issue)
                color: theme.mode === "dark" ? "white" : "inherit", // fix for label not being white (v4/v5 transition issue)
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size={size ? size : "small"}
                label={label}
                variant={variant ? variant : "outlined"}
                InputLabelProps={{ shrink: true }}
                inputProps={{ ...params.inputProps, maxLength: maxLength ? maxLength : 1000 }}
                error={error ? error : false}
                required={required ? required : null}
                helperText={helperText ? <span style={{color: "red"}}>{helperText}</span> : null}
                placeholder={placeholder ? placeholder : null}
              />
            )}
            onChange={(_, data) => {props.onChange(data); onChange && onChange(_, data)}}
          />
        );
      }}
      name={name}
      defaultValue={defaultValue ? defaultValue : ""}
      control={control}
    />
  );
}

export default withStyles(styles)(Index);
