import { put, call } from "redux-saga/effects";
import { receiptMakerConstants, alertConstants, authConstants } from "_constants";
import { adminReceiptMakerService } from "_services";
import { adminReceiptMakerActions } from "_actions";

export function* getV3ReceiptsSaga(action) {
  try {
    const { startDate, endDate } = action.payload;
    const response = yield call(adminReceiptMakerService.getV3Receipts, startDate, endDate);
    yield put(adminReceiptMakerActions.getV3ReceiptsSuccess(response));
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({ type: authConstants.LOGOUT });
    }
    yield put(adminReceiptMakerActions.getV3ReceiptsFailure(error.message));
    yield put({ type: alertConstants.ERROR, payload: { message: error.message } });
  }
}

export function* getSpecialReceiptsSaga(action) {
  try {
    const { startDate, endDate } = action.payload;
    const response = yield call(adminReceiptMakerService.getSpecialReceipts, startDate, endDate);
    yield put(adminReceiptMakerActions.getSpecialReceiptsSuccess(response));
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({ type: authConstants.LOGOUT });
    }
    yield put(adminReceiptMakerActions.getSpecialReceiptsFailure(error.message));
    yield put({ type: alertConstants.ERROR, payload: { message: error.message } });
  }
}

export function* getSendEmailDataSaga(action) {
  try {
    const { startDate, endDate } = action.payload;
    const response = yield call(adminReceiptMakerService.getSendEmailData, startDate, endDate);
    yield put(adminReceiptMakerActions.getSendEmailDataSuccess(response));
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({ type: authConstants.LOGOUT });
    }
    yield put(adminReceiptMakerActions.getSendEmailDataFailure(error.message));
    yield put({ type: alertConstants.ERROR, payload: { message: error.message } });
  }
}
