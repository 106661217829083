import { put, call, all } from "redux-saga/effects";
import {
  adminEntityConstants_,
  //adminEntityConstants,
  alertConstants,
  authConstants,
} from "_constants";
import {
  adminEntityService_,
  //adminEntityService
} from "_services";
import { buildAlertMessage } from "_utility";
import { isEmpty } from "ramda";
import _getProperty from "lodash/get";
import { lobOptions, lobToInteger } from "_helpers";
export function* getAllEntitySaga() {
  try {
    const response = yield call(adminEntityService_.getAllEntity);

    yield put({
      type: adminEntityConstants_.GET_ALL_ADMIN_ENTITY_SUCCESS,
      payload: [...response],
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}
export function* createEntitySaga({ payload: { files, ...payload }, lob }) {
  try {
    yield call(adminEntityService_.createClientInformation, files, payload, lob);

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Entity Created" },
    });

    const response = yield call(adminEntityService_.getAllEntity);

    yield put({
      type: adminEntityConstants_.GET_ALL_ADMIN_ENTITY_SUCCESS,
      payload: [...response],
    });
    if (files !== null) {
      window.location.reload(false);
    }
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getSchemaLobSaga() {
  try {
    const response = yield call(adminEntityService_.getSchemaLob);

    yield put({
      type: adminEntityConstants_.GET_ALL_ADMIN_SCHEMA_LOB_SUCCESS,
      payload: [...response],
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getAdminEntityStatusSaga({ entityId, lob }) {
  try {
    yield put({
      type: adminEntityConstants_.SET_ADMIN_ENTITY_STATUS_LOADING_TRUE,
    });

    const response = yield call(adminEntityService_.getAllStatus, entityId, lob);

    yield put({
      type: adminEntityConstants_.GET_ALL_ADMIN_ENTITY_STATUS_SUCCESS,
      payload: {
        entityId,
        lob,
        status: [...response],
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateAdminEntityStatusSaga({ entityId, lob, dataEdits }) {
  try {
    yield put({
      type: adminEntityConstants_.SET_ADMIN_ENTITY_STATUS_LOADING_TRUE,
    });

    let transformData = Object.keys(dataEdits).reduce((prev, id) => {
      let item = { ...dataEdits[id], id };
      return [...prev, item];
    }, []);
    const responses = yield all(transformData.map((item) => call(adminEntityService_.updateStatus, lob, item)));
    if (isEmpty(responses)) {
      throw new Error("Error updating statuses");
    }
    let statusMessage = buildAlertMessage("Status", responses);
    const fetchStatuses = yield call(adminEntityService_.getAllStatus, entityId, lob);
    yield put({
      type: adminEntityConstants_.GET_ALL_ADMIN_ENTITY_STATUS_SUCCESS,
      payload: {
        entityId,
        lob,
        status: [...fetchStatuses],
      },
    });
    yield put({
      type: alertConstants.SUCCESS,
      payload: {
        message: `${statusMessage}`,
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getAdminEntitySowContractsSaga({ entityId, lob }) {
  try {
    yield put({
      type: adminEntityConstants_.SET_ADMIN_ENTITY_SOW_CONTRACTS_LOADING_TRUE,
    });
    const response = yield call(adminEntityService_.getAllSowContracts, entityId, lob);

    yield put({
      type: adminEntityConstants_.GET_ALL_ADMIN_ENTITY_SOW_CONTRACTS_SUCCESS,
      payload: {
        entityId,
        lob,
        sowContracts: [...response],
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateAdminEntitySowContractsSaga({ entityId, lob, dataEdits }) {
  try {
    yield put({
      type: adminEntityConstants_.SET_ADMIN_ENTITY_SOW_CONTRACTS_LOADING_TRUE,
    });

    let transformData = Object.keys(dataEdits).reduce((prev, id) => {
      let item = { ...dataEdits[id], id };
      return [...prev, item];
    }, []);

    const responses = yield all(
      transformData.map((item) => call(adminEntityService_.updateSowContract, entityId, lob, item))
    );
    if (isEmpty(responses)) {
      throw new Error("Error updating sow contracts");
    }
    let statusMessage = buildAlertMessage("Sow Contract", responses);

    const fetchSowContracts = yield call(adminEntityService_.getAllSowContracts, entityId, lob);

    yield put({
      type: adminEntityConstants_.GET_ALL_ADMIN_ENTITY_SOW_CONTRACTS_SUCCESS,
      payload: {
        entityId,
        lob,
        sowContracts: [...fetchSowContracts],
      },
    });
    yield put({
      type: alertConstants.SUCCESS,
      payload: {
        message: `${statusMessage}`,
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}
export function* addAdminEntitySowContractSaga({ payload, entityId, lob }) {
  try {
    const response = yield call(adminEntityService_.addSowContract, payload, entityId, lob);

    if (isEmpty(response)) {
      return;
    }
    const fetchSowContracts = yield call(adminEntityService_.getAllSowContracts, entityId, lob);

    yield put({
      type: adminEntityConstants_.GET_ALL_ADMIN_ENTITY_SOW_CONTRACTS_SUCCESS,
      payload: {
        entityId,
        lob,
        sowContracts: [...fetchSowContracts],
      },
    });
    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Sow contract added" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getAdminBillingTypesSchemaSaga({ entityId, lob }) {
  try {
    const response = yield call(adminEntityService_.getBillingTypesSchema, entityId, lob);

    let result = response.reduce(
      (prev, el) => [
        ...prev,
        {
          label: el["billingTypeName"],
          value: parseInt(el["billingTypeID"]),
        },
      ],
      []
    );

    yield put({
      type: adminEntityConstants_.GET_ADMIN_ENTITY_SCHEMA_BILLING_TYPES_SUCCESS,
      payload: {
        entityId,
        lob,
        billingTypes: [...result],
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getAdminRdaSchemaSaga({ entityId, lob }) {
  try {
    const response = yield call(adminEntityService_.getRdaSchema, entityId, lob);

    let result = response.reduce((prev, el) => [...prev, { label: el["rdaName"], value: el["rdaId"] }], []);

    yield put({
      type: adminEntityConstants_.GET_ADMIN_ENTITY_SCHEMA_RDA_SUCCESS,
      payload: {
        entityId,
        lob,
        rdaId: [...result],
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getAdminSubaccountSchemaSaga({ entityId, lob }) {
  try {
    const response = yield call(adminEntityService_.getSubaccountSchema, entityId, lob);

    let result = response.reduce(
      (prev, el) => [
        ...prev,
        {
          label: el["subaccountName"],
          value: parseInt(el["subaccountId"]),
        },
      ],
      []
    );

    yield put({
      type: adminEntityConstants_.ADMIN_ENTITY_SCHEMA_SUB_ACCOUNTS_SUCCESS,
      payload: {
        entityId,
        lob,
        subaccountOptions: [...result],
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getAdminEntityRdaControlSaga({ entityId, lob }) {
  try {
    yield put({
      type: adminEntityConstants_.SET_ADMIN_ENTITY_RDA_CONTROL_LOADING_TRUE,
    });

    const response = yield call(adminEntityService_.getRdaControl, entityId, lob);
    yield put({
      type: adminEntityConstants_.GET_ALL_ADMIN_ENTITY_RDA_CONTROL_SUCCESS,
      payload: {
        entityId,
        lob,
        rdaControl: { ...response },
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getAdminEntityRdaOptionsSchemaSaga({ entityId, lob }) {
  try {
    const response = yield call(adminEntityService_.getRdaOptionsSchema, entityId, lob);

    yield put({
      type: adminEntityConstants_.GET_ADMIN_ENTITY_RDA_OPTIONS_SCHEMA_SUCCESS,
      payload: {
        entityId,
        lob,
        rdaOptions: { ...response },
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateAdminEntityRdaControlsSaga({ entityId, lob, dataEdits }) {
  try {
    yield put({
      type: adminEntityConstants_.SET_ADMIN_ENTITY_RDA_CONTROL_LOADING_TRUE,
    });
    let transformData = Object.keys(dataEdits).reduce((prev, id) => {
      let item = { ...dataEdits[id], id };
      return [...prev, item];
    }, []);

    const responses = yield all(
      transformData.map((item) => call(adminEntityService_.updateRdaControl, entityId, lob, item))
    );

    if (isEmpty(responses)) {
      throw new Error("Error updating RDA Control");
    }
    let statusMessage = buildAlertMessage("RDA Control", responses);
    const fetchRdaControl = yield call(adminEntityService_.getRdaControl, entityId, lob);

    yield put({
      type: adminEntityConstants_.GET_ALL_ADMIN_ENTITY_RDA_CONTROL_SUCCESS,
      payload: {
        entityId,
        lob,
        rdaControl: { ...fetchRdaControl },
      },
    });
    yield put({
      type: alertConstants.SUCCESS,
      payload: {
        message: `${statusMessage}`,
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* addAdminEntityRdaControlSaga({ rda, entityId, lob }) {
  try {
    const response = yield call(adminEntityService_.addRdaControl, rda, entityId, lob);
    if (isEmpty(response)) {
      return;
    }
    const fetchRdaControl = yield call(adminEntityService_.getRdaControl, entityId, lob);

    yield put({
      type: adminEntityConstants_.GET_ALL_ADMIN_ENTITY_RDA_CONTROL_SUCCESS,
      payload: {
        entityId,
        lob,
        rdaControl: {...fetchRdaControl},
      },
    });
    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Rda added" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getAdminEntityBillingTypesSaga({ entityId, lob }) {
  try {
    yield put({
      type: adminEntityConstants_.SET_ADMIN_ENTITY_BILLING_TYPES_LOADING_TRUE,
    });
    const response = yield call(adminEntityService_.getBillingTypes, entityId, lob);

    yield put({
      type: adminEntityConstants_.GET_ADMIN_ENTITY_BILLING_TYPES_SUCCESS,
      payload: {
        entityId,
        lob,
        billingTypes: [...response],
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}
export function* addAdminEntityBillingTypeSaga({ payload, entityId, lob }) {
  try {
    yield put({
      type: adminEntityConstants_.SET_ADMIN_ENTITY_BILLING_TYPES_LOADING_TRUE,
    });
    const response = yield call(adminEntityService_.addBillingType, payload, entityId, lob);
    if (isEmpty(response)) {
      return;
    }
    const responseBillingTypes = yield call(adminEntityService_.getBillingTypes, entityId, lob);
    yield put({
      type: adminEntityConstants_.GET_ADMIN_ENTITY_BILLING_TYPES_SUCCESS,
      payload: {
        entityId,
        lob,
        billingTypes: [...responseBillingTypes],
      },
    });
    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Billing Type Added" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateAdminEntityBillingTypeSaga({ payload, entityId, lob }) {
  try {
    yield put({
      type: adminEntityConstants_.SET_ADMIN_ENTITY_BILLING_TYPES_LOADING_TRUE,
    });
    const response = yield call(adminEntityService_.updateBillingType, payload, entityId, lob);
    if (isEmpty(response)) {
      return;
    }
    const responseBillingTypes = yield call(adminEntityService_.getBillingTypes, entityId, lob);
    yield put({
      type: adminEntityConstants_.GET_ADMIN_ENTITY_BILLING_TYPES_SUCCESS,
      payload: {
        entityId,
        lob,
        billingTypes: [...responseBillingTypes],
      },
    });
    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Billing Type Updated" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getAdminEntitySubAccountsSaga({ entityId, lob }) {
  try {
    yield put({
      type: adminEntityConstants_.SET_ADMIN_ENTITY_SUB_ACCOUNTS_LOADING_TRUE,
    });
    const response = yield call(adminEntityService_.getSubAccounts, entityId, lob);
    yield put({
      type: adminEntityConstants_.GET_ADMIN_ENTITY_SUB_ACCOUNTS_SUCCESS,
      payload: {
        entityId,
        lob,
        subAccounts: [...response],
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getAdminEntitySubaccountRdaSchemaSaga({ entityId, lob }) {
  try {
    const response = yield call(adminEntityService_.getSubaccountRda);
    yield put({
      type: adminEntityConstants_.GET_ADMIN_ENTITY_SUBACCOUNTS_RDA_SCHEMA_SUCCESS,
      payload: {
        entityId,
        lob,
        subaccountRda: { ...response },
      },
    });
  } catch (error) {
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateAdminEntitySubAccountsSaga({ entityId, lob, dataEdits }) {
  try {
    yield put({
      type: adminEntityConstants_.SET_ADMIN_ENTITY_SUB_ACCOUNTS_LOADING_TRUE,
    });

    let transformData = Object.keys(dataEdits).reduce((prev, id) => {
      let item = { ...dataEdits[id], id };
      return [...prev, item];
    }, []);

    const responses = yield all(
      transformData.map((item) => call(adminEntityService_.updateSubAccount, entityId, lob, item))
    );

    if (isEmpty(responses)) {
      throw new Error("Error updating Sub Accounts");
    }
    let statusMessage = buildAlertMessage("Sub Accounts", responses);
    const fetchSubAccounts = yield call(adminEntityService_.getSubAccounts, entityId, lob);

    yield put({
      type: adminEntityConstants_.GET_ADMIN_ENTITY_SUB_ACCOUNTS_SUCCESS,
      payload: {
        entityId,
        lob,
        subAccounts: [...fetchSubAccounts],
      },
    });
    yield put({
      type: alertConstants.SUCCESS,
      payload: {
        message: `${statusMessage}`,
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* addAdminEntitySubAccountSaga({ payload, entityId, lob }) {
  try {
    yield call(adminEntityService_.addSubAccount, entityId, lob, payload);

    const responseSubAccounts = yield call(adminEntityService_.getSubAccounts, entityId, lob);
    yield put({
      type: adminEntityConstants_.GET_ADMIN_ENTITY_SUB_ACCOUNTS_SUCCESS,
      payload: {
        entityId,
        lob,
        subAccounts: [...responseSubAccounts],
      },
    });
    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Sub Account added" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getAdminEntityContactsSaga({ entityId, lob }) {
  try {
    yield put({
      type: adminEntityConstants_.SET_ADMIN_ENTITY_CONTACTS_LOADING_TRUE,
    });
    const response = yield call(adminEntityService_.getContacts, entityId, lob);

    yield put({
      type: adminEntityConstants_.GET_ADMIN_ENTITY_CONTACTS_SUCCESS,
      payload: {
        entityId,
        lob,
        contacts: [...response],
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateAdminEntityContactsSaga({ entityId, lob, dataEdits }) {
  try {
    yield put({
      type: adminEntityConstants_.SET_ADMIN_ENTITY_CONTACTS_LOADING_TRUE,
    });

    let transformData = Object.keys(dataEdits).reduce((prev, id) => {
      let item = { ...dataEdits[id], id };
      return [...prev, item];
    }, []);

    const responses = yield all(
      transformData.map((item) => call(adminEntityService_.updateContact, entityId, lob, item))
    );

    if (isEmpty(responses)) {
      throw new Error("Error updating Contacts");
    }
    let statusMessage = buildAlertMessage("Contacts", responses);
    const fetchContacts = yield call(adminEntityService_.getContacts, entityId, lob);

    yield put({
      type: adminEntityConstants_.GET_ADMIN_ENTITY_CONTACTS_SUCCESS,
      payload: {
        entityId,
        lob,
        contacts: [...fetchContacts],
      },
    });
    yield put({
      type: alertConstants.SUCCESS,
      payload: {
        message: `${statusMessage}`,
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* addAdminEntityContactSaga({ contact, entityId, lob }) {
  try {
    yield call(adminEntityService_.addContact, contact, entityId, lob);
    const fetchContacts = yield call(adminEntityService_.getContacts, entityId, lob);

    yield put({
      type: adminEntityConstants_.GET_ADMIN_ENTITY_CONTACTS_SUCCESS,
      payload: {
        entityId,
        lob,
        contacts: [...fetchContacts],
      },
    });
    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Contact Added" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getDocTemplateInvoiceSaga({ entityId, lob }) {
  try {
    const response = yield call(adminEntityService_.getDocTemplateInvoice, entityId, lob);

    let { payload } = response;

    yield put({
      type: adminEntityConstants_.GET_ADMIN_ENTITY_DOC_INVOICE_TEMPLATE_SUCCESS,
      payload: {
        entityId,
        lob,
        data: payload,
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateDocTemplateInvoiceSaga({ entityId, lob, payload }) {
  try {
    yield call(adminEntityService_.updateDocTemplateInvoice, entityId, lob, payload);

    const response = yield call(adminEntityService_.getDocTemplateInvoice, entityId, lob);

    let { payload: updatedTemplate } = response;

    yield put({
      type: adminEntityConstants_.GET_ADMIN_ENTITY_DOC_INVOICE_TEMPLATE_SUCCESS,
      payload: {
        entityId,
        lob,
        data: updatedTemplate,
      },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "template updated" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getDocFieldMentionsSaga({ entityId, lob }) {
  try {
    const response = yield call(adminEntityService_.getDocFieldMentions, entityId, lob);

    yield put({
      type: adminEntityConstants_.GET_ADMIN_ENTITY_SCHEMA_DOCS_FIELD_MENTIONS_SUCCESS,
      payload: {
        entityId,
        lob,
        data: response,
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getDocTemplateClaimReasonSchemaSaga({ entityId, lob }) {
  try {
    const response = yield call(adminEntityService_.getDocClaimReasonSchema, entityId, lob);

    yield put({
      type: adminEntityConstants_.GET_ADMIN_ENTITY_SCHEMA_DOCS_CLAIM_REASONS_SUCCESS,
      payload: {
        entityId,
        lob,
        data: response,
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getDocTemplateClaimLastSentenceSchemaSaga({ entityId, lob }) {
  try {
    const response = yield call(adminEntityService_.getDocClaimLastSentenceSchema, entityId, lob);

    yield put({
      type: adminEntityConstants_.GET_ADMIN_ENTITY_SCHEMA_DOCS_CLAIM_LAST_SENTENCE_SUCCESS,
      payload: {
        entityId,
        lob,
        data: response,
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

//Claim Letter Contacts

export function* getDocTemplateClaimContactsSchemaSaga({ lob }) {
  try {
    const response = yield all(lob.map((item) => call(adminEntityService_.getDocClaimContactsSchema, item)));

    let lobNameSwitch = {
      1: "telecom",
      2: "power-gas",
      3: "frelo",
    };
    let lobName = {};
    let data = {};
    let isMultiple = false;

    if (!Array.isArray(lob)) {
      lobName = lobNameSwitch[lob];
      data = response;
    }
    if (Array.isArray(lob)) {
      isMultiple = true;
      // add all key to data object
      data.all = {
        contacts: [],
        locateCompanies: [],
      };

      response.forEach((item, index) => {
        const lobName = lobNameSwitch[lob[index]];
        // store in object by lob name key
        data[lobName] = {
          contacts: response[index].contacts,
          locateCompanies: response[index].locateCompanies,
        };
        // add into object "all" key
        data.all = {
          contacts: [...data.all.contacts, ...response[index].contacts].sort((a, b) => b.LCID - a.LCID),
          locateCompanies: [...data.all.locateCompanies, ...response[index].locateCompanies],
        };
      });
    }

    yield put({
      type: adminEntityConstants_.GET_ADMIN_ENTITY_SCHEMA_DOCS_CLAIM_CONTACTS_SUCCESS,
      payload: {
        isMultiple,
        lobName,
        data,
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateDocTemplateClaimContactsSchemaSaga({ payload, cb }) {
  try {

    const contactResponse = yield call(adminEntityService_.updateDocClaimContactsSchema, payload, payload["id"]);

    if (isEmpty(contactResponse)) {
      throw new Error("Error updating Contacts");
    }

    const lob = [1,2];
    const response = yield all(lob.map((item) => call(adminEntityService_.getDocClaimContactsSchema, item)));

    let lobNameSwitch = {
      1: "telecom",
      2: "power-gas",
      3: "frelo",
    };
    let lobName = {};
    let data = {};
    let isMultiple = false;

    if (Array.isArray(lob)) {
      isMultiple = true;
      // add all key to data object
      data.all = {
        contacts: [],
        locateCompanies: [],
      };

      response.forEach((item, index) => {
        const lobName = lobNameSwitch[lob[index]];
        // store in object by lob name key
        data[lobName] = {
          contacts: response[index].contacts,
          locateCompanies: response[index].locateCompanies,
        };
        // add into object "all" key
        data.all = {
          contacts: [...data.all.contacts, ...response[index].contacts].sort((a, b) => b.LCID - a.LCID),
          locateCompanies: [...data.all.locateCompanies, ...response[index].locateCompanies],
        };
      });
    }

    yield put({
      type: adminEntityConstants_.GET_ADMIN_ENTITY_SCHEMA_DOCS_CLAIM_CONTACTS_SUCCESS,
      payload: {
        isMultiple,
        lobName,
        data,
      },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: payload.delete ? 'Contact Deleted' : 'Contact Updated' },
    });

    if (cb) cb();

  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* addDocTemplateClaimContactsSchemaSaga({ payload, id, cb }) {
  try {
    yield call(adminEntityService_.addDocClaimContactsSchema, payload, id);

    const lob = [1,2];
    const response = yield all(lob.map((item) => call(adminEntityService_.getDocClaimContactsSchema, item)));

    let lobNameSwitch = {
      1: "telecom",
      2: "power-gas",
      3: "frelo",
    };
    let lobName = {};
    let data = {};
    let isMultiple = false;

    if (Array.isArray(lob)) {
      isMultiple = true;
      // add all key to data object
      data.all = {
        contacts: [],
        locateCompanies: [],
      };

      response.forEach((item, index) => {
        const lobName = lobNameSwitch[lob[index]];
        // store in object by lob name key
        data[lobName] = {
          contacts: response[index].contacts,
          locateCompanies: response[index].locateCompanies,
        };
        // add into object "all" key
        data.all = {
          contacts: [...data.all.contacts, ...response[index].contacts].sort((a, b) => b.LCID - a.LCID),
          locateCompanies: [...data.all.locateCompanies, ...response[index].locateCompanies],
        };
      });
    }

    yield put({
      type: adminEntityConstants_.GET_ADMIN_ENTITY_SCHEMA_DOCS_CLAIM_CONTACTS_SUCCESS,
      payload: {
        isMultiple,
        lobName,
        data,
      },
    });
    
    if (cb) cb();

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Contact Added" },
    });

  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

////End

export function* updateDocTemplateClaimSaga({ entityId, lob, letterType, payload }) {
  try {
    yield put({
      type: adminEntityConstants_.SET_ADMIN_ENTITY_DOC_MANAGEMENT_LOADING_TRUE,
    });

    yield call(adminEntityService_.updateDocTemplateClaim, entityId, lob, letterType, payload);

    const res = yield call(adminEntityService_.getDocTemplateClaim, entityId, lob, letterType);

    let { response } = res;

    yield put({
      type: adminEntityConstants_.GET_ADMIN_ENTITY_DOC_CLAIM_TEMPLATE_SUCCESS,
      payload: {
        entityId,
        lob,
        data: response,
      },
    });
    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "template updated" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getDocTemplateClaimSaga({ entityId, lob, letterType }) {
  try {
    yield put({
      type: adminEntityConstants_.SET_ADMIN_ENTITY_DOC_MANAGEMENT_LOADING_TRUE,
    });
    const payload = yield call(adminEntityService_.getDocTemplateClaim, entityId, lob, letterType);

    let { response } = payload;

    yield put({
      type: adminEntityConstants_.GET_ADMIN_ENTITY_DOC_CLAIM_TEMPLATE_SUCCESS,
      payload: {
        entityId,
        lob,
        data: response,
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getAdminEntityRdaControlRegionsSaga({ entityId, lob }) {
  try {
    const response = yield call(adminEntityService_.getRdaControlRegions, entityId, lob);
    let data = _getProperty(response, ["results"]);

    yield put({
      type: adminEntityConstants_.GET_ADMIN_ENTITY_RDA_CONTROL_ALL_REGIONS_SUCCESS,
      payload: { data, entityId, lob },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* postAdminEntityRdaControlRegionsSaga({ regionName, lob, entityId }) {
  try {
    let payload = {
      regionName,
      lob: lobOptions[lob],
      lobid: lobToInteger[lob],
      entityId: parseInt(entityId),
      active: true,
    };

    yield call(adminEntityService_.addRdaControlRegions, payload);
    const response = yield call(adminEntityService_.getRdaControlRegions, entityId, lob);
    let data = _getProperty(response, ["results"]);

    yield put({
      type: adminEntityConstants_.GET_ADMIN_ENTITY_RDA_CONTROL_ALL_REGIONS_SUCCESS,
      payload: { data, entityId, lob },
    });

    // refresh rda options for create modal dropdown
    const rdaOptionsSchemaResponse = yield call(adminEntityService_.getRdaOptionsSchema, entityId, lob);
    yield put({
      type: adminEntityConstants_.GET_ADMIN_ENTITY_RDA_OPTIONS_SCHEMA_SUCCESS,
      payload: {
        entityId,
        lob,
        rdaOptions: { ...rdaOptionsSchemaResponse },
      },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Region Added" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateAdminEntityRdaControlsRegionsSaga({ entityId, lob, data }) {
  try {
    const responses = yield all(data.map((item) => call(adminEntityService_.updateRdaControlRegion, item, lob)));

    if (isEmpty(responses)) {
      throw new Error("Error updating RDA Control Regions");
    }
    let statusMessage = buildAlertMessage("RDA Control Regions", responses);

    const response = yield call(adminEntityService_.getRdaControlRegions, entityId, lob);

    let data_ = _getProperty(response, ["results"]);

    yield put({
      type: adminEntityConstants_.GET_ADMIN_ENTITY_RDA_CONTROL_ALL_REGIONS_SUCCESS,
      payload: { data: data_, entityId, lob },
    });

    // refresh rda options for create modal dropdown
    const rdaOptionsSchemaResponse = yield call(adminEntityService_.getRdaOptionsSchema, entityId, lob);
    yield put({
      type: adminEntityConstants_.GET_ADMIN_ENTITY_RDA_OPTIONS_SCHEMA_SUCCESS,
      payload: {
        entityId,
        lob,
        rdaOptions: { ...rdaOptionsSchemaResponse },
      },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: {
        message: `${statusMessage}`,
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getAdminEntityRdaControlDivisionsSaga({ entityId, lob }) {
  try {
    const response = yield call(adminEntityService_.getRdaControlDivisions, entityId, lob);

    let data = _getProperty(response, ["results"]);
    yield put({
      type: adminEntityConstants_.GET_ADMIN_ENTITY_RDA_CONTROL_ALL_DIVISIONS_SUCCESS,
      payload: { data, entityId, lob },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* postAdminEntityRdaControlDivisionsSaga({ divisionName, lob, entityId }) {
  try {
    let payload = {
      divisionName,
      lob: lobOptions[lob],
      entityId: parseInt(entityId),
      active: true,
    };

    yield call(adminEntityService_.addRdaControlDivisions, payload);
    const response = yield call(adminEntityService_.getRdaControlDivisions, entityId, lob);

    let data = _getProperty(response, ["results"]);
    yield put({
      type: adminEntityConstants_.GET_ADMIN_ENTITY_RDA_CONTROL_ALL_DIVISIONS_SUCCESS,
      payload: { data, entityId, lob },
    });

    // refresh rda options for create modal dropdown
    const rdaOptionsSchemaResponse = yield call(adminEntityService_.getRdaOptionsSchema, entityId, lob);
    yield put({
      type: adminEntityConstants_.GET_ADMIN_ENTITY_RDA_OPTIONS_SCHEMA_SUCCESS,
      payload: {
        entityId,
        lob,
        rdaOptions: { ...rdaOptionsSchemaResponse },
      },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Division Added" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateAdminEntityRdaControlsDivisionsSaga({ entityId, lob, data }) {
  try {
    const responses = yield all(data.map((item) => call(adminEntityService_.updateRdaControlDivision, item, lob)));
    if (isEmpty(responses)) {
      throw new Error("Error updating RDA Control Regions");
    }
    let statusMessage = buildAlertMessage("RDA Control Divisions", responses);
    const response = yield call(adminEntityService_.getRdaControlDivisions, entityId, lob);

    let data_ = _getProperty(response, ["results"]);
    yield put({
      type: adminEntityConstants_.GET_ADMIN_ENTITY_RDA_CONTROL_ALL_DIVISIONS_SUCCESS,
      payload: { data: data_, entityId, lob },
    });

    // refresh rda options for create modal dropdown
    const rdaOptionsSchemaResponse = yield call(adminEntityService_.getRdaOptionsSchema, entityId, lob);
    yield put({
      type: adminEntityConstants_.GET_ADMIN_ENTITY_RDA_OPTIONS_SCHEMA_SUCCESS,
      payload: {
        entityId,
        lob,
        rdaOptions: { ...rdaOptionsSchemaResponse },
      },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: {
        message: `${statusMessage}`,
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getAdminEntityRdaControlAreasSaga({ entityId, lob }) {
  try {
    const response = yield call(adminEntityService_.getRdaControlAreas, entityId, lob);

    let data = _getProperty(response, ["results"]);
    yield put({
      type: adminEntityConstants_.GET_ADMIN_ENTITY_RDA_CONTROL_ALL_AREAS_SUCCESS,
      payload: { data, entityId, lob },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* postAdminEntityRdaControlAreasSaga({ areaName, lob, entityId }) {
  try {
    let payload = {
      areaName,
      lob: lobOptions[lob],
      entityId: parseInt(entityId),
      active: true,
    };
    yield call(adminEntityService_.addRdaControlAreas, payload);
    const response = yield call(adminEntityService_.getRdaControlAreas, entityId, lob);

    let data = _getProperty(response, ["results"]);
    yield put({
      type: adminEntityConstants_.GET_ADMIN_ENTITY_RDA_CONTROL_ALL_AREAS_SUCCESS,
      payload: { data, entityId, lob },
    });

    // refresh rda options for create modal dropdown
    const rdaOptionsSchemaResponse = yield call(adminEntityService_.getRdaOptionsSchema, entityId, lob);
    yield put({
      type: adminEntityConstants_.GET_ADMIN_ENTITY_RDA_OPTIONS_SCHEMA_SUCCESS,
      payload: {
        entityId,
        lob,
        rdaOptions: { ...rdaOptionsSchemaResponse },
      },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Area Added" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateAdminEntityRdaControlsAreasSaga({ entityId, lob, data }) {
  try {
    const responses = yield all(data.map((item) => call(adminEntityService_.updateRdaControlArea, item, lob)));
    if (isEmpty(responses)) {
      throw new Error("Error updating RDA Control Areas");
    }
    let statusMessage = buildAlertMessage("RDA Control Areas", responses);
    const response = yield call(adminEntityService_.getRdaControlAreas, entityId, lob);

    let data_ = _getProperty(response, ["results"]);
    yield put({
      type: adminEntityConstants_.GET_ADMIN_ENTITY_RDA_CONTROL_ALL_AREAS_SUCCESS,
      payload: { data: data_, entityId, lob },
    });

    // refresh rda options for create modal dropdown
    const rdaOptionsSchemaResponse = yield call(adminEntityService_.getRdaOptionsSchema, entityId, lob);
    yield put({
      type: adminEntityConstants_.GET_ADMIN_ENTITY_RDA_OPTIONS_SCHEMA_SUCCESS,
      payload: {
        entityId,
        lob,
        rdaOptions: { ...rdaOptionsSchemaResponse },
      },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: {
        message: `${statusMessage}`,
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getInvoicingSaga({ entityId, lobId }) {
  try {
    const response = yield call(adminEntityService_.getInvoicing, entityId, lobId);

    if (isEmpty(response)) {
      throw new Error("Error retrieiving invoicing data");
    }

    yield put({
      type: adminEntityConstants_.GET_ADMIN_ENTITY_INVOICING_SUCCESS,
      payload: { data: response, entityId, lobId },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateInvoicingSaga({ entityId, lobId, settings, newLineItems, editLineItems, deleteLineItems }) {
  try {
    // update settings
    const response = yield call(adminEntityService_.updateInvoicing, settings, entityId, lobId);
    if (isEmpty(response)) {
      throw new Error("Error updating invoicing data");
    }

    if (newLineItems.length > 0) {
      const responseNew = yield all(
        newLineItems.map((item) => call(adminEntityService_.postLineItem, item, entityId, lobId))
      );
      if (isEmpty(responseNew)) {
        throw new Error("Error creating invoicing line item");
      }
    }

    if (editLineItems.length > 0) {
      const responseEdit = yield all(
        editLineItems.map((item) => call(adminEntityService_.putLineItem, item, entityId, lobId))
      );
      if (isEmpty(responseEdit)) {
        throw new Error("Error editing invoicing line item");
      }
    }

    if (deleteLineItems.length > 0) {
      const responseDelete = yield all(
        deleteLineItems.map((lineItemId) => call(adminEntityService_.deleteLineItem, lineItemId))
      );
      if (isEmpty(responseDelete)) {
        throw new Error("Error deleting invoicing line item");
      }
    }

    const responseInvoicing = yield call(adminEntityService_.getInvoicing, entityId, lobId);

    if (isEmpty(responseInvoicing)) {
      throw new Error("Error retrieiving invoicing data");
    }

    yield put({
      type: adminEntityConstants_.GET_ADMIN_ENTITY_INVOICING_SUCCESS,
      payload: { data: responseInvoicing, entityId, lobId, continueLoading: true },
    });

    const responseLineItems = yield call(adminEntityService_.getLineItems, entityId, lobId);

    if (isEmpty(responseLineItems)) {
      throw new Error("Error retrieving line items");
    }

    yield put({
      type: adminEntityConstants_.GET_ADMIN_ENTITY_INVOICING_LINE_ITEMS_SUCCESS,
      payload: { data: responseLineItems, entityId, lobId },
    });

    // const data = response.data;

    // yield put({
    //   type:
    //     adminEntityConstants_.UPDATE_ADMIN_ENTITY_INVOICING_SUCCESS,
    //     payload: { data: data, entityId, lobId },
    // });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getInvoicingLineItemsSaga({ entityId, lobId }) {
  try {
    const response = yield call(adminEntityService_.getLineItems, entityId, lobId);

    if (isEmpty(response)) {
      throw new Error("Error retrieving line items");
    }

    yield put({
      type: adminEntityConstants_.GET_ADMIN_ENTITY_INVOICING_LINE_ITEMS_SUCCESS,
      payload: { data: response, entityId, lobId },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}
