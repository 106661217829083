import { fork } from "redux-saga/effects";
import {
  watchUserAuthentication,
  watchAdminKnowledgeBase,
  watchAdminUsers,
  watchAdminEntity,
  watchAdminEntity_,
  watchAdminRoles,
  watchAdminPowerBiRoles,
  watchAdminSchema,
  watchAdminNotifications,
  watchAdminRoundRobin,
  watchAdminClaimStatus,
  watchAdminRecoveryAgent,
  watchAdminStates,
  watchAdminFormControl,
  watchAdminJobCosting,
  watchTelecomClaimSaga,
  watchReportTelecomSaga,
  watchDispatchTelecomSaga,
  watchPowerGasClaimsSaga,
  watchPowerGasDispatchSaga,
  watchReportPowerGasSaga,
  watchFreloProjectSaga,
  watchFreloReportsSaga,
  watchJointReportsSaga,
  watchMobileSaga,
  watchErrorSaga,
  watchPowerBiSaga,
  watchPaymentSaga,
  watchAdminReceiptMaker,
  watchJointLedgerSaga,
} from "./watchers";

export default function* startForman() {
  yield fork(watchUserAuthentication);
  yield fork(watchAdminKnowledgeBase);
  yield fork(watchAdminUsers);
  yield fork(watchAdminEntity);
  yield fork(watchAdminEntity_);
  yield fork(watchAdminRoles);
  yield fork(watchAdminPowerBiRoles);
  yield fork(watchAdminSchema);
  yield fork(watchAdminNotifications);
  yield fork(watchAdminRoundRobin);
  yield fork(watchAdminClaimStatus);
  yield fork(watchAdminRecoveryAgent);
  yield fork(watchAdminStates);
  yield fork(watchAdminFormControl);
  yield fork(watchAdminJobCosting);
  yield fork(watchTelecomClaimSaga);
  yield fork(watchDispatchTelecomSaga);
  yield fork(watchReportTelecomSaga);
  yield fork(watchPowerGasClaimsSaga);
  yield fork(watchPowerGasDispatchSaga);
  yield fork(watchReportPowerGasSaga);
  yield fork(watchFreloProjectSaga);
  yield fork(watchFreloReportsSaga);
  yield fork(watchJointReportsSaga);
  yield fork(watchMobileSaga);
  yield fork(watchErrorSaga);
  yield fork(watchPowerBiSaga);
  yield fork(watchPaymentSaga);
  yield fork(watchAdminReceiptMaker);
  yield fork(watchJointLedgerSaga);
}
