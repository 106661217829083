import { put, call } from "redux-saga/effects";
import {
  authConstants,
  alertConstants,
  permissionConstants,
  adminEntityConstants,
  adminEntityConstants_,
  adminknowledgeBaseConstants,
  adminUserConstants,
  adminRoundRobinConstants,
  lobConstants,
  adminRoleConstants,
  adminSchemaConstants,
  settingsConstants,
  freloProjectConstants,
  freloReportConstants,
  telecomClaimConstants,
  telecomDispatchConstants,
  telecomReportConstants,
  powerGasClaimConstants,
  powerGasDispatchConstants,
  powerGasReportConstants,
  jointReportConstants,
} from "../_constants";
import { userService, cookieService } from "_services";
import { isEmpty, hasIn } from "ramda";

const urlLOB = window.location.pathname.match(/telecom/g)
  ? "TEL"
  : window.location.pathname.match(/power-gas/g)
  ? "POWGAS"
  : window.location.pathname.match(/frelo/g)
  ? "FRELO"
  : window.location.pathname.match(/admin/g)
  ? "Admin"
  : null;

export function* loginSaga(payload) {
  try {
    const response = yield call(userService.login, payload);
    if (isEmpty(response)) {
      throw new Error("Error authenticating user");
    }

    // ----- Note ------
    // Due to Cors, we set the cookie manually on localhost,
    // in production the node api sets the cookie

    if (
      (!process.env.NODE_ENV || process.env.NODE_ENV === "development") &&
      (!process.env.REACT_APP_TEST_API_CALLS || process.env.REACT_APP_TEST_API_CALLS === "false")
    ) {
      if (!hasIn("Token", response)) {
        console.log("env variable trouble");
        throw new Error("Error authenticating user");
      }
      yield call(cookieService.set, "token", `${response.Token}`);
    }

    if (!hasIn("routePermissions", response) || !hasIn("LOBAccess", response) || !hasIn("isAdmin", response)) {
      throw new Error("Error authenticating user");
    }

    yield put({
      type: authConstants.LOGIN_SUCCESS,
      payload: {
        user: response["userId"],
        username: response["username"],
        role: response["role"],
        assumeUser: false,
        assumeRole: false,
        defaultRoleId: response["role"]["roleId"],
        powerBiRoleId: response["powerBiRoleId"],
        arc: response['arc'],
        rcr: response['rcr']
      },
    });

    // context
    // Ensure default lob is in LobAccess array. else handle setting another line of business
    if (isEmpty(response["LOBAccess"]) === false) {
      // if lob access array is empty then dont dispatch set current lob
      if (response["LOBAccess"].includes(response["defaultLob"])) {
        // Grab default lob if its in lob access
        yield put({
          type: lobConstants.CURRENT_LOB_SET,
          payload: urlLOB ? urlLOB : response["defaultLob"],
        });
      } else if (response["LOBAccess"][0]) {
        // if default lob is not in array then grab the first item in the array and set as current lob
        yield put({
          type: lobConstants.CURRENT_LOB_SET,
          payload: urlLOB ? urlLOB : response["LOBAccess"][0],
        });
      }
    }

    yield put({
      type: permissionConstants.PERMISSION_SET,
      payload: {
        routes: { ...response["routePermissions"] },
        isAdmin: response["isAdmin"],
        LOBAccess: [...response["LOBAccess"]],
        actionAccess: response["actionAccessData"],
        statusAccess: response["statusAccessData"],
        formControl: response["allFormControlPermissions"],
      },
    });
  } catch (error) {
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
    yield put({ type: authConstants.LOGIN_FAILURE });
  }
}

// clear all state
export function* logoutSaga() {
  try {
    yield call(cookieService.remove, "token");

    yield put({
      type: adminEntityConstants.ENTITY_CLEAR,
    });

    yield put({
      type: adminEntityConstants_.ADMIN_ENTITY_CLEAR,
    });

    // Tel
    yield put({ type: telecomClaimConstants.TELECOM_CLAIM_CLEAR });
    yield put({ type: telecomDispatchConstants.TELECOM_DISPATCH_CLEAR });

    yield put({ type: telecomReportConstants.TELECOM_REPORTS_CLEAR });

    // power gas
    yield put({ type: powerGasClaimConstants.POWER_GAS_CLAIM_CLEAR });
    yield put({ type: powerGasDispatchConstants.POWER_GAS_DISPATCH_CLEAR });
    yield put({ type: powerGasReportConstants.POWER_GAS_REPORTS_CLEAR });

    // Frelo
    yield put({
      type: freloProjectConstants.FRELO_PROJECT_CLEAR,
    });

    yield put({ type: freloReportConstants.FRELO_REPORTS_CLEAR });

    // Joint
    yield put({ type: jointReportConstants.JOINT_REPORTS_CLEAR });

    // Admin
    yield put({
      type: adminRoleConstants.ADMIN_ROLES_CLEAR,
    });

    yield put({
      type: adminSchemaConstants.ADMIN_SCHEMA_CLEAR,
    });

    yield put({
      type: adminknowledgeBaseConstants.ADMIN_KNOWLEDGE_BASE_CLEAR,
    });

    yield put({
      type: adminUserConstants.ADMIN_USERS_CLEAR,
    });

    yield put({
      type: adminRoundRobinConstants.ADMIN_ROUNDROBIN_CLEAR,
    });

    // Settings
    yield put({
      type: permissionConstants.PERMISSION_CLEAR,
    });

    yield put({
      type: lobConstants.LOB_CLEAR,
    });

    yield put({
      type: settingsConstants.SETTINGS_CLEAR,
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Logged out" },
    });

    // Redux persist
    localStorage.clear();
  } catch (error) {
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

//Assume User

export function* assumeUserSaga({ payload, currentUser }) {
  try {
    const response = yield call(userService.assumeUser, payload);
    if (isEmpty(response)) {
      throw new Error("Error authenticating user");
    }
    // ----- Note ------
    // Due to Cors, we set the cookie manually on localhost,
    // in production the node api sets the cookie

    if (
      (!process.env.NODE_ENV || process.env.NODE_ENV === "development") &&
      (!process.env.REACT_APP_TEST_API_CALLS || process.env.REACT_APP_TEST_API_CALLS === "false")
    ) {
      if (!hasIn("Token", response)) {
        console.log("env variable trouble");
        throw new Error("Error authenticating user");
      }
      yield call(cookieService.set, "Token", `${response.Token}`);
    }

    if (!hasIn("routePermissions", response) || !hasIn("LOBAccess", response) || !hasIn("isAdmin", response)) {
      throw new Error("Error authenticating user");
    }

    yield put({
      type: authConstants.LOGIN_SUCCESS,
      payload: {
        user: response["userId"],
        username: response["username"],
        role: response["role"],
        powerBiRoleId: response["powerBiRoleId"],
        defaultRoleId: currentUser === undefined ? response["role"]["roleId"] : undefined,
        assumeUser: currentUser && currentUser !== undefined ? true : false,
        currentUser: currentUser,
      },
    });

    // context
    // Ensure default lob is in LobAccess array. else handle setting another line of business
    if (isEmpty(response["LOBAccess"]) === false) {
      // if lob access array is empty then dont dispatch set current lob
      if (response["LOBAccess"].includes(response["defaultLob"])) {
        // Grab default lob if its in lob access
        yield put({
          type: lobConstants.CURRENT_LOB_SET,
          payload: urlLOB ? urlLOB : response["defaultLob"],
        });
      } else if (response["LOBAccess"][0]) {
        // if default lob is not in array then grab the first item in the array and set as current lob
        yield put({
          type: lobConstants.CURRENT_LOB_SET,
          payload: urlLOB ? urlLOB : response["LOBAccess"][0],
        });
      }
    }

    yield put({
      type: permissionConstants.PERMISSION_SET,
      payload: {
        routes: { ...response["routePermissions"] },
        isAdmin: response["isAdmin"],
        LOBAccess: [...response["LOBAccess"]],
        actionAccess: response["actionAccessData"],
        statusAccess: response["statusAccessData"],
        formControl: response["allFormControlPermissions"],
      },
    });
  } catch (error) {
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
    yield put({ type: authConstants.LOGIN_FAILURE });
  }
}

//Assume Role

export function* assumeRoleSaga({ payload, assumerole, currentRole, username, user }) {
  try {
    const response = yield call(userService.assumeRole, payload);
    if (isEmpty(response)) {
      throw new Error("Error authenticating user");
    }

    if (!hasIn("routePermissions", response) || !hasIn("LOBAccess", response) || !hasIn("isAdmin", response)) {
      throw new Error("Error authenticating user");
    }

    yield put({
      type: authConstants.LOGIN_SUCCESS,
      payload: {
        user: user,
        username: username,
        role: response["role"],
        assumeRole: assumerole && assumerole,
        defaultRoleId: currentRole,
        powerBiRoleId: response["powerBiRoleId"],
      },
    });

    // context
    // Ensure default lob is in LobAccess array. else handle setting another line of business
    if (isEmpty(response["LOBAccess"]) === false) {
      // if lob access array is empty then dont dispatch set current lob
      if (response["LOBAccess"].includes(response["defaultLob"])) {
        // Grab default lob if its in lob access
        yield put({
          type: lobConstants.CURRENT_LOB_SET,
          payload: urlLOB ? urlLOB : response["defaultLob"],
        });
      } else if (response["LOBAccess"][0]) {
        // if default lob is not in array then grab the first item in the array and set as current lob
        yield put({
          type: lobConstants.CURRENT_LOB_SET,
          payload: urlLOB ? urlLOB : response["LOBAccess"][0],
        });
      }
    }

    yield put({
      type: permissionConstants.PERMISSION_SET,
      payload: {
        routes: { ...response["routePermissions"] },
        isAdmin: response["isAdmin"],
        LOBAccess: [...response["LOBAccess"]],
        actionAccess: response["actionAccessData"],
        statusAccess: response["statusAccessData"],
        formControl: response["allFormControlPermissions"],
      },
    });
  } catch (error) {
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
    yield put({ type: authConstants.LOGIN_FAILURE });
  }
}

//End Impersonation

export function* impersonationSaga() {
  try {
    yield call(userService.endImpersonation);

    yield put({
      type: authConstants.IMPERSONATION_END_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}
