import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Popover from "@mui/material/Popover";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { ObjectTools } from "_utility";
import { isEmpty } from "ramda";
import Autocomplete from "_components/core/Autocomplete_";

let LOBOptions = [
  { value: 1, label: "Telecom" },
  { value: 2, label: "Power and Gas" },
];

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: "-100px",
    padding: "40px",
  },
}));

export default function FindPaymentForm({ formData, setSubmitted }) {
  const classes = useStyles();
  const { getNestedPropertySafe } = ObjectTools;
  const { register, handleSubmit, setValue, control } = useForm();

  const [noLobId, setNoLobId] = useState(false);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const invoice = urlParams.get("invoice");
    const lobidURL = urlParams.get("lobid");
    if (invoice) setValue("invoice", invoice);
    if (!lobidURL) setNoLobId(true);
  }, [setValue]);

  const [anchorNeedHelp, setAnchorNeedHelp] = useState();
  const [open, setOpen] = useState(null);
  const handleClick = (event, id) => {
    setAnchorNeedHelp(event.currentTarget);
    setOpen(id);
  };

  const handleClose = () => {
    setAnchorNeedHelp(null);
    setOpen(null);
  };

  const id = open ? "simple-popover" : undefined;

  const [errors, setErrors] = useState({});
  const onError = (errors) => {
    setErrors(errors);
  };

  const onSubmit = (data, e) => {
    setErrors({});
    setSubmitted(data);
  };

  const payment = useSelector((state) => getNestedPropertySafe(state, ["payment", "info"]));
  const loading = useSelector((state) => getNestedPropertySafe(state, ["payment", "loading"]));

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit, onError)}>
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h5">Outside Plant Damage (OPD) Recovery Services</Typography>
        <Typography variant="h5">PAYMENT CENTER</Typography>
      </Box>

      <Divider sx={{ margin: "20px 0 30px" }} />

      <Box sx={{ textAlign: "center", marginBottom: "40px" }}>
        <Typography variant="subtitle2">
          Welcome to the PRG Payment Center for Outside Plant Damage Recovery Services.
        </Typography>
        <Typography variant="subtitle2">Login below to begin.</Typography>
      </Box>

      <Grid container spacing={{ xs: 4, md: 12 }}>
        <Grid item xs={12} md={6}>
          <Stack spacing={2}>
            <Typography variant="body2">
              Through this site, you can log-in to review your claim settlement and pay the full amount of the
              settlement by credit card. To process your payment for an OPD claim, you will need the Invoice Number and
              Claim Confirmation Code from either the letter or email you received concerning your claim.
            </Typography>
            <Typography variant="body2">
              After entering your Invoice Number and Claim Confirmation Code, you will get a screen with details about
              your damage claim and a secure credit card form where you can enter your card information and pay for your
              damage claim. PRG accepts VISA, MasterCard, Discover and American Express cards.
            </Typography>
            <img alt="logo" className={classes.image} src="/images/cardlogos.jpg" />
            {/* <Typography variant="caption">Need help? Click here</Typography> */}
            <Button
              aria-describedby={id}
              variant="text"
              onClick={(event) => handleClick(event, "need-help")}
              sx={{ width: "fit-content" }}
            >
              Need help?
            </Button>
            <Popover
              id={id}
              open={open === "need-help"}
              anchorNeedHelp={anchorNeedHelp}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Box sx={{ p: 4 }}>
                <Stack spacing={2}>
                  <Typography variant="button">HELP WITH YOUR OPD PAYMENT</Typography>
                  <Typography variant="body1">
                    Your PRG Recovery Agent can assist you with making your claim payment.
                  </Typography>
                  <Typography variant="body2">
                    <a href="mailto:jlosch@PRGconsulting.net">Jay Losch</a> - (720) 795-9960
                  </Typography>
                  <Typography variant="body2">
                    <a href="mailto:rking@prgconsulting.net">Rochelle King</a> - (720) 795-9971
                  </Typography>
                </Stack>
              </Box>
            </Popover>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={8} sx={{ textAlign: "center", padding: "30px", borderRadius: "4px" }}>
            <Typography variant="button" sx={{ marginBottom: "20px", paddingBottom: "20px" }}>
              OPD CLAIM PAYMENT
            </Typography>
            <Stack spacing={2} sx={{ marginTop: "20px" }}>
              {noLobId && <Autocomplete
                options={LOBOptions ? LOBOptions : []}
                label="Line of Business"
                name="lobid"
                control={control}
                required
                error={errors.hasOwnProperty("lobid")}
                helperText={errors["lobid"] && "Line of Business is required"}
              />}
              <TextField
                id="invoice"
                label="Invoice Number"
                name="invoice"
                variant="outlined"
                size="small"
                required
                inputRef={register({ required: true, minLength: 6, maxLength: 6 })}
                error={errors.hasOwnProperty("invoice")}
                helperText={errors["invoice"] && "Invoice Number must be 5 characters"}
              />
              {!loading && formData && isEmpty(payment) && (
                <Typography cariant="caption" sx={{ color: "red", fontSize: "12px" }}>
                  No claim found
                </Typography>
              )}
              {!loading ? (
                <Button type="submit" variant="contained" sx={{ color: "white" }}>
                  Submit
                </Button>
              ) : (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress color="primary" />
                </Box>
              )}
            </Stack>
          </Paper>
        </Grid>
      </Grid>

      <Stack sx={{ textAlign: "center", alignItems: "center", mt: "20px" }}>
        <Typography variant="caption">
          This website is secured with SSL protection. The safe processing of your claim is our top priority.
        </Typography>
        <Button
          variant="text"
          onClick={(event) => handleClick(event, "privacy-statement")}
          sx={{ width: "fit-content" }}
        >
          Privacy Statement
        </Button>
        <Popover
          id={id}
          open={open === "privacy-statement"}
          anchorNeedHelp={anchorNeedHelp}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Box sx={{ p: 4 }}>
            <Stack spacing={2}>
              <Typography variant="button">PRIVACY STATEMENT</Typography>
              <Typography variant="body2">
                This privacy policy discloses the privacy practices for https://app.prgconsulting.net. This privacy
                policy applies solely to information collected by this web site.
              </Typography>
              <Typography variant="button">Security</Typography>
              <Typography variant="body2">
                PRG takes precautions to protect your information. When you submit sensitive information via the
                website, your information is protected both online and offline. Wherever you are asked for sensitive
                information (such as credit card data), that information is encrypted and transmitted to a PCI Compliant
                third-party processor in a secure way. You can verify this by looking for a closed lock icon in the
                address section of the website, or looking for "https" at the beginning of the address of the web page.
                Each page within this site also links to the Secure Certificate which users can validate.
              </Typography>
              <Typography variant="body2">
                While PRG uses encryption to protect sensitive information transmitted online, we also protect your
                information offline. PRG never collects or stores your credit card number, expiration date or type of
                card used. PRG does collect the transaction authorization number, which will allow us to reference the
                transaction with the third-party processor should a need arise.
              </Typography>
            </Stack>
          </Box>
        </Popover>
        <Typography variant="caption">©Project Resources Group, 2024.</Typography>
      </Stack>
    </form>
  );
}
