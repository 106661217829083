import { getApiBase } from "_helpers";
import { cookieService } from "_services";

const baseRequest = (endpoint, body) => {
  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `?jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/admin/receiptMaker/${endpoint}${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error(`Error fetching ${endpoint}`);
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};
const getV3Receipts = (startDate, endDate) => baseRequest("v3-receipt-data", { startDate, endDate });
const getSpecialReceipts = (startDate, endDate) => baseRequest("special-receipt-data", { startDate, endDate });
const getSendEmailData = (startDate, endDate) => baseRequest("send-email-data", { startDate, endDate });

export const adminReceiptMakerService = {
  getV3Receipts,
  getSpecialReceipts,
  getSendEmailData,
};
