import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
//import AssessmentIcon from '@mui/icons-material/Assessment';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import Collapse from "@material-ui/core/Collapse";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { FreloLinks } from "../../_data/links/FreloLinks";
import { TelecomLinks } from "../../_data/links/TelecomLinks";
import { PowerGasLinks } from "../../_data/links/PowerGasLinks";
import { AdminLinks } from "../../_data/links/AdminLinks";
import { DemoLinks } from "../../_data/links/DemoLinks";

import { useSelector } from "react-redux";
import { isEmpty } from "ramda";
import { ObjectTools } from "_utility";
import { useDispatch } from "react-redux";
import { activeLinksActions } from "_actions";
import roleGroups from "_views/Dashboard/_data/roleGroups";


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    //marginLeft: "7px",
    // backgroundColor: theme.palette.DarkGrayPrg.main,
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%"
    },
    "& .MuiCollapse-wrapper": {
      backgroundColor: theme.palette.LightGrayPrg.main,
      borderRadius: "4px",
    },
    "& .MuiListItem-root": {
      color: theme.palette.OffWhitePrg.main,
      "& .MuiTypography-displayBlock": {
        fontSize: "15px",
        whiteSpace: "nowrap",
        color: `${theme.palette.OffWhitePrg.main} !important`,
        [theme.breakpoints.down("xs")]: {
          fontSize: 20
        },
      },
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  icons: {
    color: theme.palette.WhitePrg.main,
  },
  active: {
    backgroundColor: theme.palette.GreenPrg.main,
    paddingLeft: theme.spacing(4),
  },
  activeIcon: {
    color: `${theme.palette.GreenPrg.main} !important`,
  },
  bOne: {
    backgroundColor: theme.palette.GreenPrg.main,
    marginTop: 10

  }
}));

let NestedList = ({ collapse, openDrawer, toggleDrawer, handleBOneReportModal }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { getNestedPropertySafe } = ObjectTools;

  const [targetParent, setTargetParent] = useState();
  const [activeLinks, setActiveLinks] = useState([]);
  const [active, setActive] = useState(null);

  const history = useHistory();

  let linkMap = {
    TEL: TelecomLinks,
    POWGAS: PowerGasLinks,
    FRELO: FreloLinks,
    Admin: AdminLinks,
    Demo: DemoLinks,
  };

  const permissions = useSelector((state) =>
    getNestedPropertySafe(state, ["permission", "routes"])
  );

  const selectedLob = useSelector((state) =>
    getNestedPropertySafe(state, ["lob", "current"])
  );

  const auth = useSelector((state) =>
    getNestedPropertySafe(state, ["auth"])
  );
  const user = auth;
  const role = auth.role;


  let unlockRoutes = (entity, perms) => {

    let unlockedRoutes = [];
    let links = linkMap[entity];

    links.every((elm) => {

      // pass through admin parent route, admin routes will only be passed into this func if redux.permission.isAdmin === true
      if (elm.id === "admin") {
        unlockedRoutes.push({
          ...elm,
          access: true,
        });
        return true;
      }

      // handle children routes
      if (elm.root === false) {
        let targetPerm = perms[entity][elm.parentId]?.[elm.id];
        unlockedRoutes.push({
          ...elm,
          access: targetPerm ? targetPerm["GET"] : false // if targetPerm is undefined, default route access to false
        });
        return true;
      }

      let permObj = perms[entity][elm.id] ? perms[entity][elm.id]["Overview"] : null; // if Overview is undefined, default route access to false
      unlockedRoutes.push({
        ...elm,
        access: permObj ? permObj["GET"] : false
      });
      return true;
    });

    setActiveLinks(unlockedRoutes);
    dispatch(activeLinksActions.setActiveLinks(unlockedRoutes));
  };

  useEffect(() => {
    if (selectedLob && permissions) {
      if (!isEmpty(permissions)) {
        unlockRoutes(selectedLob, permissions);
      }
    }
    // eslint-disable-next-line
  }, [selectedLob, permissions]);

  let handleClick = (url, parentId) => {
    if (url) {
      history.push(url);
      setActive(url);
      toggleDrawer();
    } else {
      openDrawer();
    }
    if (parentId) {
      setTargetParent(parentId);
    }
  };

  const openARC = () => {
    window.open("https://sites.google.com/prgconsulting.net/recovery-agent-resources/prg", "_blank");
  }

  const openRCR = () => {
    window.open("https://sites.google.com/prgconsulting.net/recovery-claim-resources/home", "_blank");
  }

  const openKB = () => {
    window.open("https://sites.google.com/prgconsulting.net/opd-knowledge-base/home", "_blank");
  }

  return (
    <CollapseWrapper>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        className={`${classes.root} ${collapse && "link"} ${(selectedLob === "Admin" || selectedLob === "POWGAS") && "adminNav"}`}
      >
        {activeLinks &&
          activeLinks
            .filter((item) => item.root && item.access && item.name !== "MyTeam")
            .map((item, i) => (
              <div key={i}>
                <ListItem
                  button
                  className={
                    targetParent === item.id
                      ? classes.activeIcon
                      : classes.icons
                  }
                  onClick={() => handleClick(item.url, item.id)}
                  disabled={item.access === false}
                >
                  <ListItemIcon>
                    <Icon
                      className={
                        targetParent === item.id
                          ? classes.activeIcon
                          : classes.icons
                      }
                    >
                      {item.icon}
                    </Icon>
                  </ListItemIcon>
                  <ListItemText primary={item.name} />
                </ListItem>

                <Collapse
                  in={(targetParent === item.id && collapse) || (selectedLob === "Admin" && collapse)}
                  timeout="auto"
                  unmountOnExit
                  className={classes.root}
                >
                  <List component="div" disablePadding>
                    {activeLinks
                      .filter(
                        (item) => {
                          return selectedLob === "Admin" ? item.root !== true : item.parentId === targetParent && item.root !== true && item.access
                        }
                      )
                      .map((link, i) => {
                        return (
                          <ListItem
                            button
                            key={i}
                            disabled={link.access === false}
                            onClick={() => handleClick(link.url, null)}
                            className={
                              active === link.url
                                ? classes.active
                                : classes.nested
                            }
                          >
                            <ListItemText primary={link.name} />
                            {active === link.url && <NavigateNextIcon />}
                          </ListItem>
                        );
                      })}
                  </List>
                </Collapse>
              </div>
            ))}
            {role.RoundRobin &&
          <ListItem
            button
            onClick={handleBOneReportModal}
            className={classes.bOne}
          >
            <ListItemIcon>
              <Icon
                className={classes.icons
                }
              >
                <Typography variant="h6" style={{ lineHeight: 1.2 }}>RR</Typography>
                {/* <AssessmentIcon/> */}
              </Icon>
            </ListItemIcon>
            <ListItemText primary={"Round Robin"} style={{ whiteSpace: 'nowrap' }} />
          </ListItem>
        }

        {role.B1Access &&
          <ListItem
            button
            onClick={handleBOneReportModal}
            className={classes.bOne}
          >
            <ListItemIcon>
              <Icon
                className={classes.icons
                }
              >
                <Typography variant="h6" style={{ lineHeight: 1.2 }}>B1</Typography>
                {/* <AssessmentIcon/> */}
              </Icon>
            </ListItemIcon>
            <ListItemText primary={"B1 Scoreboard"} style={{ whiteSpace: 'nowrap' }} />
          </ListItem>
        }
        {(user.arc !== null ? user.arc : role.arc) &&
          <ListItem
            button
            onClick={openARC}
            className={classes.bOne}
          >
            <ListItemIcon>
                <Typography variant="h6" style={{ fontSize: "16px", marginLeft: "-5px" }}>ARC</Typography>
            </ListItemIcon>
            <ListItemText primary={"Agent Resource Center"} style={{ whiteSpace: 'nowrap' }} />
          </ListItem>
        }
        {(user.rcr !== null ? user.rcr : role.rcr) &&
          <ListItem
            button
            onClick={openRCR}
            className={classes.bOne}
          >
            <ListItemIcon>
                <Typography variant="h6" style={{ fontSize: "16px", marginLeft: "-5px" }}>RCR</Typography>
            </ListItemIcon>
            <ListItemText primary={"Rec. Claim Resource"} style={{ whiteSpace: 'nowrap' }} />
          </ListItem>
        }
        {![...roleGroups.TEL_EXTERNAL, ...roleGroups.PG_EXTERNAL].includes(role.roleId) &&
          <ListItem
            button
            onClick={openKB}
            className={classes.bOne}
          >
            <ListItemIcon>
                <Typography variant="h6" style={{ fontSize: "16px", marginLeft: "-5px" }}>KB</Typography>
            </ListItemIcon>
            <ListItemText primary={"Knowledge Base"} style={{ whiteSpace: 'nowrap' }} />
          </ListItem>
        }
      </List>

    </CollapseWrapper>
  );
};

// max-height: fit-content;
const CollapseWrapper = styled.div`
  nav {
    top: 75px;
    xbackground-color: var(--DarkGray);
    .MuiCollapse-container {
      background-color: var(--LightGray);
      border-radius: 4px;
      max-width: 97%;
      max-height: 720px;
      overflow-y: overlay;
      overflow-x: hidden;
      margin: auto;
      .MuiListItem-root {
        color: var(--OffWhite);
        .MuiTypography-displayBlock {
          font-size: 14px;
          white-space: normal;
        }
      }
    }
  }
  @media (max-width: 600px) {
    .link:not(.link.adminNav) {
      top: 110px;
    }
  }
`;
export default NestedList;
