export const receiptMakerConstants = {
  GET_V3_RECEIPTS_REQUEST: "GET_V3_RECEIPTS_REQUEST",
  GET_V3_RECEIPTS_SUCCESS: "GET_V3_RECEIPTS_SUCCESS",
  GET_V3_RECEIPTS_FAILURE: "GET_V3_RECEIPTS_FAILURE",
  GET_SPECIAL_RECEIPTS_REQUEST: "GET_SPECIAL_RECEIPTS_REQUEST",
  GET_SPECIAL_RECEIPTS_SUCCESS: "GET_SPECIAL_RECEIPTS_SUCCESS",
  GET_SPECIAL_RECEIPTS_FAILURE: "GET_SPECIAL_RECEIPTS_FAILURE",
  GET_SEND_EMAIL_DATA_REQUEST: "GET_SEND_EMAIL_DATA_REQUEST",
  GET_SEND_EMAIL_DATA_SUCCESS: "GET_SEND_EMAIL_DATA_SUCCESS",
  GET_SEND_EMAIL_DATA_FAILURE: "GET_SEND_EMAIL_DATA_FAILURE",
};
